export const posts = [
    {
        cat: 'search engine marketing',
        title: 'How To Do Keyword Research For Google Ads',
        desc: 'Optimize your Google Ads with our keyword research guide, featuring tools, competitor analysis, and SERP insights',
        author: 'Francesca Romano',
        date: '29 Jul 2024',
        n: 1
    },
    {
        cat: 'seo',
        title: 'How To Measure Keyword Density For Your SEO',
        desc: "Measure keyword density for SEO with our guide. Learn to choose and analyze keywords to ensure optimal usage and improve your content's performance",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 2
    },
    {
        cat: 'seo',
        title: 'How to Perform a Search Engine Crawlability Test for Better SEO',
        desc: "Learn how a search engine crawlability test can improve your website’s SEO by ensuring it is fully indexed and optimized",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 3
    },
    {
        cat: 'seo',
        title: 'How To Perform Backlink Management For Your Website',
        desc: "Enhance your SEO strategy by mastering backlink management with MarketLab’s advanced backlink tools",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 4
    },
    {
        cat: 'social media marketing',
        title: 'How Social Media Affects SEO',
        desc: "Explore the impact of social media on SEO and how you can leverage it to enhance your search engine rankings. Learn effective strategies for optimizing your social presence and driving better SEO results",
        author: 'Francesca Romano',
        date: '31 Jul 2024',
        n: 5
    },
    {
        cat: 'seo&digital marketing',
        title: 'Why Is It Important for Marketers to Research the Competition',
        desc: "Learn why understanding your competitors is vital for effective marketing and how to use MarketLab to gain valuable insights",
        author: 'Francesca Romano',
        date: '2 Aug 2024',
        n: 6
    },
    {
        cat: 'seo',
        title: 'How to Find and Optimize Sitemaps for Enhanced SEO',
        desc: "A comprehensive guide on finding and optimizing sitemaps to improve your SEO efforts",
        author: 'Francesca Romano',
        date: '3 Aug 2024',
        n: 7
    },
    {
        cat: 'seo',
        title: 'Technical SEO VS On Page SEO',
        desc: "Discover the key differences between Technical SEO and On-Page SEO and why mastering both is essential for boosting your website’s performance",
        author: 'Francesca Romano',
        date: '5 Aug 2024',
        n: 8
    },
    {
        cat: 'seo',
        title: 'What Next After Completing Keyword Research',
        desc: "Explore essential steps to take after completing keyword research to enhance your SEO strategy",
        author: 'Alvaro Hernandez',
        date: '7 Aug 2024',
        n: 9
    },
    {
        cat: 'seo',
        title: 'Natural Backlinks Ideas',
        desc: "Uncover creative natural backlinks ideas and strategies to elevate your SEO with engaging content and innovative techniques",
        author: 'Alvaro Hernandez',
        date: '12 Aug 2024',
        n: 10
    },
    {
        cat: 'seo&digital marketing',
        title: 'How to Perform Competition Research',
        desc: "Discover how to leverage MarketLab’s tools to conduct effective competition research with detailed visual insights",
        author: 'Francesca Romano',
        date: '18 Aug 2024',
        n: 11
    },
    {
        cat: 'seo&technical seo',
        title: 'Alternate Pages and Proper Canonical Tag Use for SEO',
        desc: "Learn how to manage alternate pages and use canonical tags correctly to enhance your SEO performance",
        author: 'Francesca Romano',
        date: '19 Aug 2024',
        n: 12
    },
    {
        cat: 'seo&technical seo',
        title: 'Robots txt and Meta Robots Tags',
        desc: "Discover how to use robots.txt and meta robots tags to control search engine crawling and indexing, optimizing your SEO strategy with our detailed guide",
        author: 'Francesca Romano',
        date: '19 Aug 2024',
        n: 13
    },
    {
        cat: 'digital marketing',
        title: 'PR In Digital Marketing',
        desc: "Learn how to leverage public relations (PR) in digital marketing to enhance brand visibility, trust, and engagement",
        author: 'Francesca Romano',
        date: '23 Aug 2024',
        n: 14
    },
    {
        cat: 'email marketing',
        title: 'How Can Email Marketing Fuel Your Overall Inbound Strategy',
        desc: "Learn how email marketing can enhance your inbound strategy by nurturing leads and driving conversions",
        author: 'Francesca Romano',
        date: '23 Aug 2024',
        n: 15
    },
    {
        cat: 'email marketing',
        title: 'How to Leverage Email Marketing for B2B Lead Generation',
        desc: "Discover proven strategies to use email marketing for generating high-quality B2B leads and driving business growth",
        author: 'Francesca Romano',
        date: '27 Aug 2024',
        n: 16
    },
    {
        cat: 'content marketing&digital marketing',
        title: 'Content Marketing for Manufacturers',
        desc: "Explore how content marketing can help manufacturers build authority, educate clients, and drive growth with actionable strategies",
        author: 'Francesca Romano',
        date: '28 Aug 2024',
        n: 17
    },
    {
        cat: 'search engine marketing',
        title: 'Search Engine Marketing Tips and Best Practices',
        desc: "Discover top search engine marketing tips and best practices to improve your SEM campaigns and drive better results",
        author: 'Francesca Romano',
        date: '29 Aug 2024',
        n: 18
    },
    {
        cat: 'seo',
        title: 'How to Create a Winning B2B SEO Campaign',
        desc: "Master the art of B2B SEO with our comprehensive guide on creating a winning campaign, powered by MarketLab’s tools",
        author: 'Francesca Romano',
        date: '1 Sep 2024',
        n: 19
    },
    {
        cat: 'digital marketing',
        title: 'How To Carry Competitive Analysis In Digital Marketing',
        desc: "Master competitive analysis in digital marketing and uncover strategies to outshine your competitors",
        author: 'Francesca Romano',
        date: '3 Sep 2024',
        n: 20
    },
    {
        cat: 'seo&technical seo&content marketing',
        title: 'Content SEO vs Technical SEO',
        desc: "Learn the key differences between content SEO and technical SEO and how to balance both for optimal search engine rankings",
        author: 'Francesca Romano',
        date: '5 Sep 2024',
        n: 21
    },
    {
        cat: 'digital marketing',
        title: 'Why Some Backlinks Have No Follow',
        desc: 'Understand the significance of "no follow" backlinks in digital marketing and how they impact your overall strategy',
        author: 'Francesca Romano',
        date: '9 Sep 2024',
        n: 22
    },
    {
        cat: 'seo',
        title: 'Building Local SEO Outside Your Core Location',
        desc: 'Learn effective strategies to expand your local SEO beyond your core location and attract customers from nearby areas',
        author: 'Francesca Romano',
        date: '11 Sep 2024',
        n: 23
    },
    {
        cat: 'seo',
        title: 'Backlink Tracking Techniques',
        desc: 'Explore essential backlink tracking techniques to optimize your link profile and boost your SEO performance',
        author: 'Francesca Romano',
        date: '15 Sep 2024',
        n: 24
    },
    {
        cat: 'email marketing',
        title: 'What Is a Mid-Cycle Marketing Email',
        desc: 'Discover how mid-cycle marketing emails can transform your lead nurturing process and skyrocket your conversions with targeted, value-packed strategies',
        author: 'Francesca Romano',
        date: '21 Sep 2024',
        n: 25
    },
    {
        cat: 'seo&digital marketing',
        title: 'Types of Backlinks to Get',
        desc: 'Discover the top 10 types of backlinks you should get for better SEO results, with actionable strategies and real-world examples',
        author: 'Francesca Romano',
        date: '3 Oct 2024',
        n: 26
    },
    {
        cat: 'seo&digital marketing',
        title: 'How To Do B2B Keyword Research',
        desc: 'Unlock the secrets of B2B keyword research to connect with decision-makers and drive your business forward',
        author: 'Francesca Romano',
        date: '17 Oct 2024',
        n: 27
    }
]