import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import img1 from '../../../media/blog/semTips1.jpeg'
import img2 from '../../../media/blog/semTips2.jpeg'


function SEMTips({ sWidth, title, desc, author, date }) {
    const category = 'search engine marketing'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Learn actionable search engine marketing tips and best practices to enhance your SEM campaigns. Discover how to optimize keywords, ad copy, landing pages, and more for successful results." />
        <title>Search Engine Marketing Tips and Best Practices: A Comprehensive Guide</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>Search Engine Marketing (SEM) is a powerful way to increase visibility, drive traffic, and generate leads. Whether you're new to SEM or looking to improve your existing strategy, understanding the best practices and applying actionable tips can make a significant difference in your campaign's success. This guide will walk you through the key components of SEM and provide you with valuable strategies to optimize your efforts.</p>

<h2>What is Search Engine Marketing (SEM)?</h2>
<p>SEM involves promoting your website through paid advertising on search engines like Google and Bing. Unlike organic search optimization (SEO), SEM focuses on paid strategies such as Pay-Per-Click (PPC) ads, where advertisers bid on keywords to display their ads to users who are actively searching for related content.</p>

<h2>1. Choose the Right Keywords</h2>
<p>The foundation of any successful SEM campaign is choosing the right keywords. Keywords are the terms and phrases that users type into search engines, and targeting the right ones ensures your ads reach the right audience.</p>

<h3>How to Choose Keywords:</h3>
<p>Let’s say you’re managing SEM for a company that sells eco-friendly office supplies. You need to identify the right keywords to attract potential buyers.</p>

<ul>
  <li><strong>Use Keyword Research Tools:</strong> <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s keyword research tool can help you discover keywords related to "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>skincare products</span>" that have high search volume and low competition. For example, you might find keywords like "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>organic skincare products</span>" and "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>natural facial cleansers</span>"</p></li>
  <img src={img1} alt='keyword research tool' />
  <li><strong>Focus on Long-Tail Keywords:</strong> <p>Long-tail keywords are more specific and often less competitive. You could identify long-tail keywords like "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>recycled paper notebooks</span>" or "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>eco-friendly office essentials</span>"</p></li>
  <li><strong>Consider User Intent:</strong> <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s intent detection feature can help you understand whether users are searching for information, products, or services. For example, "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>best organic skincare brands</span>" indicates commercial intent, while "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>how to make organic face masks</span>" signals informational intent.</p></li>
  <img src={img2} alt='keyword research tool' />
</ul>

<h2>2. Create Compelling Ad Copy</h2>
<p>Your ad copy is the first thing potential customers see, so it needs to be engaging, clear, and relevant. Crafting compelling ad copy can significantly impact your click-through rates (CTR) and overall campaign performance.</p>

<h3>Writing Effective Ad Copy for Eco-Friendly Office Supplies:</h3>
<ul>
  <li><strong>Highlight Unique Selling Points (USPs):</strong> <p>In your ad copy, you can emphasize features like "Made from 100% recycled materials" or "Certified non-toxic stationery"</p></li>
  <li><strong>Include a Strong Call-to-Action (CTA):</strong> <p>Encourage users to take action with phrases like "Shop Now for Sustainable Office Supplies" or "Get 20% Off Your First Green Office Purchase"</p></li>
  <li><strong>Use Ad Extensions:</strong> <p>Ad extensions like site links to your "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>Eco-Friendly Office Essentials</span>" category or callouts like "Free Shipping on Orders Over $50" can provide additional information and make your ad more appealing.</p></li>
</ul>

<h2>3. Optimize Landing Pages</h2>
<p>Driving traffic to your website is only half the battle. Once users click on your ad, they need to land on a page that matches their search intent and encourages them to take the next step. An optimized landing page can improve your Quality Score and conversion rates.</p>

<h3>Optimizing Your Landing Page for Eco-Friendly Office Supplies:</h3>
<ul>
  <li><strong>Ensure Relevance:</strong> <p>If your ad promotes "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>biodegradable stationery</span>
  " make sure users land on a page that showcases this specific product.</p></li>
  <li><strong>Optimize for Mobile:</strong> <p>With more users browsing on mobile devices, ensure your landing page is responsive and loads quickly.</p></li>
  <li><strong>Keep Forms Simple:</strong> <p>If your landing page includes a form to capture leads, ask for only essential information, such as name and email. Simplified forms can increase conversion rates by reducing friction.</p></li>
</ul>

<h2>4. Use A/B Testing</h2>
<p>One of the most effective ways to optimize your SEM campaigns is through A/B testing. By testing different versions of your ads, landing pages, and keywords, you can identify what works best and continuously improve your results.</p>

<h3>How to Conduct A/B Testing:</h3>
<ul>
  <li><strong>Test Ad Variations:</strong> Create two versions of your ad – one that focuses on "affordability" and another on "sustainability."</li>
  <li><strong>Test Landing Page Layouts:</strong> Try different layouts for your landing page, such as one with a video introduction and another with a product image gallery.</li>
  <li><strong>Test Keyword Variations:</strong> <p>Experiment with different keywords in your SEM campaigns. For example, compare the performance of "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>eco-friendly office supplies</span>" versus "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>sustainable office products</span>"</p></li>
</ul>

<h2>5. Monitor and Adjust Your Campaigns</h2>
<p>SEM is not a set-it-and-forget-it strategy. Continuous monitoring and adjustments are crucial to staying competitive and maximizing ROI.</p>

<h3>Ongoing Campaign Optimization:</h3>
<ul>
  <li><strong>Track Performance Metrics:</strong> Monitor key metrics such as click-through rates, conversion rates, and Quality Score. Regularly review these metrics to identify areas for improvement.</li>
  <li><strong>Adjust Bids and Budgets:</strong> If certain keywords or ads are performing well, consider increasing your bid to maximize visibility. Conversely, reduce spending on underperforming keywords.</li>
  <li><strong>Stay Updated on Trends:</strong> The SEM landscape is constantly evolving, with new trends and algorithms affecting performance. Stay ahead of the curve and adjust your strategy accordingly.</li>
</ul>

<h2>Closing Remarks</h2>
<p>Search engine marketing is a dynamic and highly effective way to drive targeted traffic to your website and generate leads. By following these tips and best practices, you can create successful SEM campaigns that deliver consistent results. From keyword research to ad copy, landing page optimization, A/B testing, and ongoing monitoring, each step plays a crucial role in achieving your SEM goals.</p>


        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>

        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>Search Engine Marketing (SEM) is a powerful way to increase visibility, drive traffic, and generate leads. Whether you're new to SEM or looking to improve your existing strategy, understanding the best practices and applying actionable tips can make a significant difference in your campaign's success. This guide will walk you through the key components of SEM and provide you with valuable strategies to optimize your efforts.</p>

<h2>What is Search Engine Marketing (SEM)?</h2>
<p>SEM involves promoting your website through paid advertising on search engines like Google and Bing. Unlike organic search optimization (SEO), SEM focuses on paid strategies such as Pay-Per-Click (PPC) ads, where advertisers bid on keywords to display their ads to users who are actively searching for related content.</p>

<h2>1. Choose the Right Keywords</h2>
<p>The foundation of any successful SEM campaign is choosing the right keywords. Keywords are the terms and phrases that users type into search engines, and targeting the right ones ensures your ads reach the right audience.</p>

<h3>How to Choose Keywords:</h3>
<p>Let’s say you’re managing SEM for a company that sells eco-friendly office supplies. You need to identify the right keywords to attract potential buyers.</p>

<ul>
  <li><strong>Use Keyword Research Tools:</strong> <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s keyword research tool can help you discover keywords related to "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>skincare products</span>" that have high search volume and low competition. For example, you might find keywords like "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>organic skincare products</span>" and "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>natural facial cleansers</span>"</p></li>
  <img src={img1} alt='keyword research tool' />
  <li><strong>Focus on Long-Tail Keywords:</strong> <p>Long-tail keywords are more specific and often less competitive. You could identify long-tail keywords like "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>recycled paper notebooks</span>" or "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>eco-friendly office essentials</span>"</p></li>
  <li><strong>Consider User Intent:</strong> <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s intent detection feature can help you understand whether users are searching for information, products, or services. For example, "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>best organic skincare brands</span>" indicates commercial intent, while "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>how to make organic face masks</span>" signals informational intent.</p></li>
  <img src={img2} alt='keyword research tool' />
</ul>

<h2>2. Create Compelling Ad Copy</h2>
<p>Your ad copy is the first thing potential customers see, so it needs to be engaging, clear, and relevant. Crafting compelling ad copy can significantly impact your click-through rates (CTR) and overall campaign performance.</p>

<h3>Writing Effective Ad Copy for Eco-Friendly Office Supplies:</h3>
<ul>
  <li><strong>Highlight Unique Selling Points (USPs):</strong> <p>In your ad copy, you can emphasize features like "Made from 100% recycled materials" or "Certified non-toxic stationery"</p></li>
  <li><strong>Include a Strong Call-to-Action (CTA):</strong> <p>Encourage users to take action with phrases like "Shop Now for Sustainable Office Supplies" or "Get 20% Off Your First Green Office Purchase"</p></li>
  <li><strong>Use Ad Extensions:</strong> <p>Ad extensions like site links to your "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>Eco-Friendly Office Essentials</span>" category or callouts like "Free Shipping on Orders Over $50" can provide additional information and make your ad more appealing.</p></li>
</ul>

<h2>3. Optimize Landing Pages</h2>
<p>Driving traffic to your website is only half the battle. Once users click on your ad, they need to land on a page that matches their search intent and encourages them to take the next step. An optimized landing page can improve your Quality Score and conversion rates.</p>

<h3>Optimizing Your Landing Page for Eco-Friendly Office Supplies:</h3>
<ul>
  <li><strong>Ensure Relevance:</strong> <p>If your ad promotes "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>biodegradable stationery</span>
  " make sure users land on a page that showcases this specific product.</p></li>
  <li><strong>Optimize for Mobile:</strong> <p>With more users browsing on mobile devices, ensure your landing page is responsive and loads quickly.</p></li>
  <li><strong>Keep Forms Simple:</strong> <p>If your landing page includes a form to capture leads, ask for only essential information, such as name and email. Simplified forms can increase conversion rates by reducing friction.</p></li>
</ul>

<h2>4. Use A/B Testing</h2>
<p>One of the most effective ways to optimize your SEM campaigns is through A/B testing. By testing different versions of your ads, landing pages, and keywords, you can identify what works best and continuously improve your results.</p>

<h3>How to Conduct A/B Testing:</h3>
<ul>
  <li><strong>Test Ad Variations:</strong> Create two versions of your ad – one that focuses on "affordability" and another on "sustainability."</li>
  <li><strong>Test Landing Page Layouts:</strong> Try different layouts for your landing page, such as one with a video introduction and another with a product image gallery.</li>
  <li><strong>Test Keyword Variations:</strong> <p>Experiment with different keywords in your SEM campaigns. For example, compare the performance of "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>eco-friendly office supplies</span>" versus "<span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>sustainable office products</span>"</p></li>
</ul>

<h2>5. Monitor and Adjust Your Campaigns</h2>
<p>SEM is not a set-it-and-forget-it strategy. Continuous monitoring and adjustments are crucial to staying competitive and maximizing ROI.</p>

<h3>Ongoing Campaign Optimization:</h3>
<ul>
  <li><strong>Track Performance Metrics:</strong> Monitor key metrics such as click-through rates, conversion rates, and Quality Score. Regularly review these metrics to identify areas for improvement.</li>
  <li><strong>Adjust Bids and Budgets:</strong> If certain keywords or ads are performing well, consider increasing your bid to maximize visibility. Conversely, reduce spending on underperforming keywords.</li>
  <li><strong>Stay Updated on Trends:</strong> The SEM landscape is constantly evolving, with new trends and algorithms affecting performance. Stay ahead of the curve and adjust your strategy accordingly.</li>
</ul>

<h2>Closing Remarks</h2>
<p>Search engine marketing is a dynamic and highly effective way to drive targeted traffic to your website and generate leads. By following these tips and best practices, you can create successful SEM campaigns that deliver consistent results. From keyword research to ad copy, landing page optimization, A/B testing, and ongoing monitoring, each step plays a crucial role in achieving your SEM goals.</p>


        </div>
    </MContainer>
    }

    </>
  )
}

export default SEMTips


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 700px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;

        > img {
        width: 100%;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 50px;
    }
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }

            > img {
                width: 100%;
                align-self: self-start;
                max-width: 1000px; 
                border-radius: 3px;
                object-fit: contain;
                margin-bottom: 50px;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: bold;
            margin-left: 10px;
            width: 90%;
            margin-top: 45px;
            font-size: 28px;
        }

        > h3 {
            font-weight: normal !important;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: larger;
            line-height: 1.8rem;
            width: 95%;

            > code {
                display: inline;
                width: 95%;
            }
        }
        
        > ul {
            margin-bottom: 35px;
            margin-top: 30px;
            font-weight: normal;
            font-size: larger;

            > li {
                font-weight: normal;
                margin-block: 35px;
                width: 90%;
                > strong {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`