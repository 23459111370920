import React, { useEffect } from 'react'
import styled from 'styled-components'
import image from '../../../media/alvaro-hernandez.jpg'
import image2 from '../../../media/alv.jpg'
import fr from '../../../media/alv-2.png'
import { posts } from '../posts'
import BlogCard from '../../BlogCard'
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const segmentArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

function AlvaroHernandez({ sWidth }) {
    const sPosts = posts.filter(p=> p.author === 'Alvaro Hernandez')

    const segmentedPosts = segmentArray(sPosts.sort((a, b) => b.n - a.n), 3);

    useEffect(() => {
        document.title = 'Improwth - Alvaro Hernandez';
        return () => {
          document.title = 'Improwth - Alvaro Hernandez';
        };
      }, []);

  return (
    <>
    {sWidth > 768? 
    <Container>
        <div>
            <div style={{width: '600px'}}>
                <h1>Alvaro Hernandez <LinkedInIcon onClick={()=> window.open('https://www.linkedin.com/in/alvaro-hernandez-195b7b210', '_blank')}/></h1>
                <p className='title'>CMO and Head of Marketing</p>
                <p className='desc'>Alvaro Hernandez is the Chief Marketing Officer (CMO) at Improwth. Combining innovative marketing strategies with a keen understanding of market dynamics, Alvaro drives the company's growth and strategic direction. His leadership enhances Improwth's market presence and strengthens its brand positioning.</p>
                <p className='desc'>Alvaro joined the company with a robust background in marketing strategy, bringing a fresh perspective and expertise to the role. His leadership has been instrumental in steering Improwth’s marketing efforts and amplifying its market impact. Alvaro’s vision and strategic approach continue to be key drivers of the company’s success.</p>
                
            </div>

            <div style={{justifyContent: 'center', alignItems: 'center', width: '700px'}}>
                <img src={image} alt='Alvaro Hernandez' />
                <div className='imgs'>
                    <img src={image2} alt='Alvaro Hernandez' />
                </div>
                
            </div>
        </div>

        <Posts>
            <h2>Latest Insights from Alvaro Hernandez</h2>
            {segmentedPosts.map(ps=> {
                
                return (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', width: '90%'}}>
                    {ps.map(post=> {
                        return (
                            <BlogCard
                                key={post.title}
                                cat = {post.cat}
                                title = {post.title} 
                                desc = {post.desc}
                                author = {post.author}
                                date = {post.date}
                            />
                        )
                    })}
                </div>)
                
            })}
        </Posts>
    </Container>
    :
    <ResponsiveContainer>
                    <div>
                        <div className="text-section">
                            <h1 style={{alignSelf: 'center'}}>Alvaro Hernandez</h1>
                            <p className='title'>CMO and Head of Marketing</p>
                            <p className='desc' style={{textAlign: 'left', marginLeft: '10px'}}>Alvaro Hernandez is the Chief Marketing Officer (CMO) at Improwth. Combining innovative marketing strategies with a keen understanding of market dynamics, Alvaro drives the company's growth and strategic direction. His leadership enhances Improwth's market presence and strengthens its brand positioning.</p>
                            <p className='desc' style={{textAlign: 'left', marginLeft: '10px'}}>Alvaro joined the company with a robust background in marketing strategy, bringing a fresh perspective and expertise to the role. His leadership has been instrumental in steering Improwth’s marketing efforts and amplifying its market impact. Alvaro’s vision and strategic approach continue to be key drivers of the company’s success.</p>
                        </div>
                    </div>

                    <Posts style={{margin: '30px 5px 100px 5px'}}>
                        <h2>Latest Insights from Alvaro Hernandez</h2>
                        {sPosts.map(post => (
                            <div style={{margin: '20px 5px'}}>
                            <h2 onClick={()=> window.location.href = `/blog/${post.title.toLowerCase().replaceAll(' ', '-')}`}  style={{fontFamily: 'Quicksand', color: 'blue'}}>{post.title}</h2>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: '13px'}}>
                                <img src={fr} alt={post.author} style={{borderRadius: '50%'}} width={37} />
                                <p style={{fontFamily: 'Quicksand', fontSize: 'small'}}>{post.author}</p>
                                <p style={{marginLeft: 'auto'}}>{post.date}</p>
                            </div>
                            </div>
                        ))}
                    </Posts>
                </ResponsiveContainer>
    }
    </>
  )
}

export default AlvaroHernandez



const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 60px;
        margin-top: 60px;
        

        > div {
            display: flex;
            flex-direction: column;
            margin: 20px;

            > h1 {
                font-weight: 500;
                font-family: 'Quicksand';
                color: blue;
                display: flex;
                align-items: center;
                gap: 25px;

                > .MuiSvgIcon-root {
                    cursor: pointer;
                    color: blue;
                    transition: color 0.3s ease-in-out;
                    align-self: start;
                    margin-top: 10px;

                    &:hover {
                        color: dodgerblue;
                    }
                }
            }

            > .title {
                color: gray;
                font-family: 'Quicksand';
            }

            > .desc {
                font-family: 'Quicksand';
                margin-top: 50px;
            }

            > img {
                object-fit: contain;
                border-radius: 4px;
                width: 200px;
            }

            > .imgs {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 30px;
                margin-top: 20px;

                > img {
                    border-radius: 4px;
                    object-fit: contain;
                    width: 260px;
                    margin-left: 20px;
                }
            }
        }
    }
`


const Posts = styled.div`
    display: flex;
    flex-direction: column !important;
    gap: 30px;
    margin-top: 60px;
    margin-bottom: 100px;

    > h2 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-bottom: 25px;
        width: 100%;
        margin-left: 25px;
    }
`



const ResponsiveContainer = styled(Container)`
    @media (max-width: 768px) {
        > div {
            flex-direction: column;

            .text-section {
                text-align: center;
                margin: 10px;
                width: 100%;
            }

            .image-section {
                width: 100%;

                > img {
                    width: 100%;
                }

                > .imgs {
                    flex-direction: column;
                    gap: 10px;

                    > img {
                        width: 100%;
                    }
                }
            }
        }
    }
`