import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import img1 from '../../../media/cr-chart1.jpeg'
import img2 from '../../../media/cr-chart2.jpeg'
import img3 from '../../../media/cr-chart3.jpeg'
import img4 from '../../../media/cr-chart4.jpeg'

function HowToUseCompetitionResearchTool({ sWidth, title, desc, author, date }) {
    const category = 'seo&digital marketing'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Learn how to use MarketLab's competition research tools, including bubble charts, pie charts, scatter plots, and SERP analysis, to gain a comprehensive understanding of your competitors and enhance your SEO strategy." />
        <title>How to Perform Competition Research | A Comprehensive Guide</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>Competition research is a cornerstone of successful SEO and digital marketing strategies. It involves understanding how your competitors perform in search engine rankings and what makes their content and backlinks effective. By analyzing this data, you can identify opportunities to enhance your own site's performance. <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s competition research tool offers powerful features to help you dive deep into your competitors' metrics. In this post, we'll explore how to use its various tools—bubble chart, pie chart, scatter chart, and SERP analysis—to gain valuable insights and improve your SEO strategy.</p>




            <h2>Key Metrics to Focus On</h2>

            <h3>Backlink Profile</h3>

            <p><strong>Why It Matters:</strong> Backlinks are crucial for SEO as they signal to search engines that your content is credible and authoritative. Understanding your competitors’ backlink profiles helps you gauge their authority and identify potential link-building opportunities.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Volume:</strong> Look at the total number of backlinks. More backlinks generally indicate higher authority.</li>
                <li><strong>Quality:</strong> Evaluate the quality of backlinks by examining the domains linking to your competitors. High-quality backlinks from reputable sites can significantly impact search rankings.</li>
            </ul>

            <h3>Domain Authority</h3>

            <p><strong>Why It Matters:</strong> Domain authority (DA) is a measure of the overall strength of a website’s domain. A higher DA often translates to better search engine rankings.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Compare DA Scores:</strong> Look at your competitors' DA scores to understand their overall authority. Higher DA scores typically indicate stronger sites.</li>
            </ul>

            <h3>Content Quality and Relevance</h3>

            <p><strong>Why It Matters:</strong> High-quality, relevant content attracts more visitors and generates better engagement. It’s essential to understand what makes your competitors’ content successful.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Content Topics:</strong> Identify the main topics and keywords your competitors are targeting.</li>
                <li><strong>Engagement Metrics:</strong> Look at metrics such as average time on page, bounce rate, and social shares to assess how well their content resonates with their audience.</li>
            </ul>

            <h3>SERP Performance</h3>

            <p><strong>Why It Matters:</strong> Analyzing SERP (Search Engine Results Page) performance helps you understand how competitors rank for specific keywords and in particular locations.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Keyword Rankings:</strong> Check where your competitors rank for key terms. This provides insight into their visibility and effectiveness in search results.</li>
                <li><strong>Local Rankings:</strong> Analyze how well competitors rank in different geographical locations, which can inform your local SEO strategy.</li>
            </ul>

            <h3>Competitor Strategies</h3>

            <p><strong>Why It Matters:</strong> Understanding competitors’ strategies helps you identify what they’re doing well and where there might be gaps in their approach that you can exploit.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Content Format and Layout:</strong> Review the format and layout of competitor content to understand their approach to engaging visitors.</li>
                <li><strong>User Experience:</strong> Evaluate the user experience on competitors’ sites to see how it compares to yours.</li>
            </ul>

            <h2>Focusing Your Competition Research</h2>

            <p>By concentrating on these key areas, you can gain valuable insights into your competitors’ strategies and performance. Here’s how to use these insights effectively:</p>

            <h3>Identify Strengths and Weaknesses</h3>

            <p>Analyze backlink profiles and domain authority to pinpoint competitors’ strengths. Look for content gaps and weaknesses that you can leverage.</p>

            <h3>Optimize Your Content Strategy</h3>

            <p>Use insights from content quality and SERP performance to refine your own content strategy, targeting similar or better keywords and addressing gaps in the content.</p>

            <h3>Improve Your SEO Tactics</h3>

            <p>Apply findings from SERP performance to adjust your SEO tactics and improve your rankings in specific locations.</p>

            <h3>Enhance User Experience</h3>

            <p>Incorporate best practices observed in competitors’ user experiences to enhance your site’s functionality and engagement.</p>





            <h2>Understanding the insights in <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span> Competition Research Tool</h2>

            <h3>Bubble Chart - Visualizing Backlinks and Page Authority</h3>

            <img src={img1} alt='Bubble Chart' />
            <p>The bubble chart is a dynamic way to visualize how competitors' pages perform in terms of backlinks and authority. Here’s how you can use it:</p>
            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Size of Bubbles:</strong>
                    </div>
                    <p>Represents the number of backlinks. Larger bubbles indicate pages with more backlinks, which often correlates with higher authority and better rankings.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Vertical Position (Y-Axis):</strong>
                    </div>
                    <p>Shows the rank of the page, with higher positions indicating better ranks.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Horizontal Position (X-Axis):</strong>
                    </div>
                    <p>Reflects the domain authority. Pages further right have higher domian authority.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Color of Bubbles:</strong>
                    </div>
                    <p>Identifies different competitors for clarity.</p>
                </li>
            </ul>



            <h3>How to Use It</h3>
            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Identify Strong Pages:</strong>
                    </div>
                    <p>Look for larger, higher-positioned bubbles to find which competitors have authoritative pages with substantial backlinks.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Compare Authority:</strong>
                    </div>
                    <p>Evaluate the horizontal position to understand which pages have the highest authority, potentially guiding your content strategy.</p>
                </li>
            </ul>


            <h3>Pie Chart: Analyzing Backlink Distribution</h3>
            <img src={img3} alt='Pie Chart' />
            <p>The pie chart breaks down the proportion of backlinks each competitor’s page holds relative to others. This tool provides a clear view of how backlinks are distributed across different pages.</p>

            <h3>How to Use It</h3>

            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Understand Backlink Distribution:</strong>
                    </div>
                    <p>See which pages or competitors have the most significant share of backlinks.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Identify Key Competitors:</strong>
                    </div>
                    <p>Pages with larger slices are crucial competitors to analyze further for backlink strategies.</p>
                </li>
            </ul>



            <h3>Scatter Chart: Comparing SEO Metrics</h3>
            <img src={img2} alt='Scatter Chart' />
            <p>The scatter chart allows for a nuanced comparison of different pages based on multiple SEO metrics. It plots pages according to their backlinks and ranks, providing a clear picture of their relative performance.</p>

            <h3>How to Use It</h3>

            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Evaluate SEO Performance:</strong>
                    </div>
                    <p>Assess which pages have high backlinks and rank well, indicating strong SEO performance.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Spot Opportunities:</strong>
                    </div>
                    <p>Find pages with high backlinks but lower ranks to explore potential areas where you can outperform competitors.</p>
                </li>
            </ul>


            <h3>SERP Analysis: Keyword Performance in specific locations</h3>
            <img src={img4} alt='SERP Analysis' style={{width: '900px'}}/>


            <p>The SERP (Search Engine Results Page) analysis tool shows how keywords perform in a specific location. It gives you insight into the search landscape and how your competitors rank for relevant keywords.</p>


            <h3>How to Use It</h3>

            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Analyze SERP Positions:</strong>
                    </div>
                    <p>Check where competitors' pages rank for specific keywords in a selected location. This helps you understand their visibility and effectiveness.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Optimize Keywords:</strong>
                    </div>
                    <p>Use this data to adjust your keyword strategy and improve your rankings in the same location.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Evaluate Competitor Strategies:</strong>
                    </div>
                    <p>Click to visit each competitor's page to see their content format, layout, and user experience. This will help you understand their approach to engaging visitors and can provide ideas for enhancing your own site’s design and functionality.</p>
                </li>
            </ul>


            <h2>Putting It All Together</h2>

            <p>By combining insights from <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>’s bubble chart, pie chart, scatter chart, and SERP analysis, you can form a comprehensive understanding of your competition. Here’s how to leverage these tools effectively</p>

            <h3>Identify Competitor Strengths</h3>
            <p>Use the bubble chart and pie chart to pinpoint competitors with high authority and significant backlink profiles.</p>

            <h3>Analyze Performance Gaps</h3>
            <p>The scatter chart helps identify areas where competitors excel and where there might be gaps you can exploit.</p>

            <h3>Refine Your Strategy</h3>
            <p>Apply the SERP analysis to fine-tune your keyword strategy and improve your rankings in the targeted location.</p>

            <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>’s competition research tool provides a robust framework for evaluating and understanding your competitors’ SEO strategies. By utilizing these insights, you can craft more effective strategies and enhance your site’s performance in search engine results.</p>



        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>
        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>Competition research is a cornerstone of successful SEO and digital marketing strategies. It involves understanding how your competitors perform in search engine rankings and what makes their content and backlinks effective. By analyzing this data, you can identify opportunities to enhance your own site's performance. <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s competition research tool offers powerful features to help you dive deep into your competitors' metrics. In this post, we'll explore how to use its various tools—bubble chart, pie chart, scatter chart, and SERP analysis—to gain valuable insights and improve your SEO strategy.</p>




            <h2>Key Metrics to Focus On</h2>

            <h3>Backlink Profile</h3>

            <p><strong>Why It Matters:</strong> Backlinks are crucial for SEO as they signal to search engines that your content is credible and authoritative. Understanding your competitors’ backlink profiles helps you gauge their authority and identify potential link-building opportunities.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Volume:</strong> Look at the total number of backlinks. More backlinks generally indicate higher authority.</li>
                <li><strong>Quality:</strong> Evaluate the quality of backlinks by examining the domains linking to your competitors. High-quality backlinks from reputable sites can significantly impact search rankings.</li>
            </ul>

            <h3>Domain Authority</h3>

            <p><strong>Why It Matters:</strong> Domain authority (DA) is a measure of the overall strength of a website’s domain. A higher DA often translates to better search engine rankings.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Compare DA Scores:</strong> Look at your competitors' DA scores to understand their overall authority. Higher DA scores typically indicate stronger sites.</li>
            </ul>

            <h3>Content Quality and Relevance</h3>

            <p><strong>Why It Matters:</strong> High-quality, relevant content attracts more visitors and generates better engagement. It’s essential to understand what makes your competitors’ content successful.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Content Topics:</strong> Identify the main topics and keywords your competitors are targeting.</li>
                <li><strong>Engagement Metrics:</strong> Look at metrics such as average time on page, bounce rate, and social shares to assess how well their content resonates with their audience.</li>
            </ul>

            <h3>SERP Performance</h3>

            <p><strong>Why It Matters:</strong> Analyzing SERP (Search Engine Results Page) performance helps you understand how competitors rank for specific keywords and in particular locations.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Keyword Rankings:</strong> Check where your competitors rank for key terms. This provides insight into their visibility and effectiveness in search results.</li>
                <li><strong>Local Rankings:</strong> Analyze how well competitors rank in different geographical locations, which can inform your local SEO strategy.</li>
            </ul>

            <h3>Competitor Strategies</h3>

            <p><strong>Why It Matters:</strong> Understanding competitors’ strategies helps you identify what they’re doing well and where there might be gaps in their approach that you can exploit.</p>

            <p><strong>How to Analyze:</strong></p>
            <ul>
                <li><strong>Content Format and Layout:</strong> Review the format and layout of competitor content to understand their approach to engaging visitors.</li>
                <li><strong>User Experience:</strong> Evaluate the user experience on competitors’ sites to see how it compares to yours.</li>
            </ul>

            <h2>Focusing Your Competition Research</h2>

            <p>By concentrating on these key areas, you can gain valuable insights into your competitors’ strategies and performance. Here’s how to use these insights effectively:</p>

            <h3>Identify Strengths and Weaknesses</h3>

            <p>Analyze backlink profiles and domain authority to pinpoint competitors’ strengths. Look for content gaps and weaknesses that you can leverage.</p>

            <h3>Optimize Your Content Strategy</h3>

            <p>Use insights from content quality and SERP performance to refine your own content strategy, targeting similar or better keywords and addressing gaps in the content.</p>

            <h3>Improve Your SEO Tactics</h3>

            <p>Apply findings from SERP performance to adjust your SEO tactics and improve your rankings in specific locations.</p>

            <h3>Enhance User Experience</h3>

            <p>Incorporate best practices observed in competitors’ user experiences to enhance your site’s functionality and engagement.</p>





            <h2>Understanding the insights in <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span> Competition Research Tool</h2>

            <h3>Bubble Chart - Visualizing Backlinks and Page Authority</h3>

            <img src={img1} alt='Bubble Chart' />
            <p>The bubble chart is a dynamic way to visualize how competitors' pages perform in terms of backlinks and authority. Here’s how you can use it:</p>
            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Size of Bubbles:</strong>
                    </div>
                    <p>Represents the number of backlinks. Larger bubbles indicate pages with more backlinks, which often correlates with higher authority and better rankings.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Vertical Position (Y-Axis):</strong>
                    </div>
                    <p>Shows the rank of the page, with higher positions indicating better ranks.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Horizontal Position (X-Axis):</strong>
                    </div>
                    <p>Reflects the domain authority. Pages further right have higher domian authority.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Color of Bubbles:</strong>
                    </div>
                    <p>Identifies different competitors for clarity.</p>
                </li>
            </ul>



            <h3>How to Use It</h3>
            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Identify Strong Pages:</strong>
                    </div>
                    <p>Look for larger, higher-positioned bubbles to find which competitors have authoritative pages with substantial backlinks.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Compare Authority:</strong>
                    </div>
                    <p>Evaluate the horizontal position to understand which pages have the highest authority, potentially guiding your content strategy.</p>
                </li>
            </ul>


            <h3>Pie Chart: Analyzing Backlink Distribution</h3>
            <img src={img3} alt='Pie Chart' />
            <p>The pie chart breaks down the proportion of backlinks each competitor’s page holds relative to others. This tool provides a clear view of how backlinks are distributed across different pages.</p>

            <h3>How to Use It</h3>

            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Understand Backlink Distribution:</strong>
                    </div>
                    <p>See which pages or competitors have the most significant share of backlinks.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Identify Key Competitors:</strong>
                    </div>
                    <p>Pages with larger slices are crucial competitors to analyze further for backlink strategies.</p>
                </li>
            </ul>



            <h3>Scatter Chart: Comparing SEO Metrics</h3>
            <img src={img2} alt='Scatter Chart' />
            <p>The scatter chart allows for a nuanced comparison of different pages based on multiple SEO metrics. It plots pages according to their backlinks and ranks, providing a clear picture of their relative performance.</p>

            <h3>How to Use It</h3>

            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Evaluate SEO Performance:</strong>
                    </div>
                    <p>Assess which pages have high backlinks and rank well, indicating strong SEO performance.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Spot Opportunities:</strong>
                    </div>
                    <p>Find pages with high backlinks but lower ranks to explore potential areas where you can outperform competitors.</p>
                </li>
            </ul>


            <h3>SERP Analysis: Keyword Performance in specific locations</h3>
            <img src={img4} alt='SERP Analysis' style={{width: '900px'}}/>


            <p>The SERP (Search Engine Results Page) analysis tool shows how keywords perform in a specific location. It gives you insight into the search landscape and how your competitors rank for relevant keywords.</p>


            <h3>How to Use It</h3>

            <ul>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Analyze SERP Positions:</strong>
                    </div>
                    <p>Check where competitors' pages rank for specific keywords in a selected location. This helps you understand their visibility and effectiveness.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Optimize Keywords:</strong>
                    </div>
                    <p>Use this data to adjust your keyword strategy and improve your rankings in the same location.</p>
                </li>
                <li>
                    <div>
                        <RadioButtonCheckedIcon /> <strong>Evaluate Competitor Strategies:</strong>
                    </div>
                    <p>Click to visit each competitor's page to see their content format, layout, and user experience. This will help you understand their approach to engaging visitors and can provide ideas for enhancing your own site’s design and functionality.</p>
                </li>
            </ul>


            <h2>Putting It All Together</h2>

            <p>By combining insights from <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>’s bubble chart, pie chart, scatter chart, and SERP analysis, you can form a comprehensive understanding of your competition. Here’s how to leverage these tools effectively</p>

            <h3>Identify Competitor Strengths</h3>
            <p>Use the bubble chart and pie chart to pinpoint competitors with high authority and significant backlink profiles.</p>

            <h3>Analyze Performance Gaps</h3>
            <p>The scatter chart helps identify areas where competitors excel and where there might be gaps you can exploit.</p>

            <h3>Refine Your Strategy</h3>
            <p>Apply the SERP analysis to fine-tune your keyword strategy and improve your rankings in the targeted location.</p>

            <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>’s competition research tool provides a robust framework for evaluating and understanding your competitors’ SEO strategies. By utilizing these insights, you can craft more effective strategies and enhance your site’s performance in search engine results.</p>

        </div>
    </MContainer>
    }

    </>
  )
}

export default HowToUseCompetitionResearchTool


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 700px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }

            > img {
                width: 100%;
                align-self: self-start;
                max-width: 1000px; 
                border-radius: 3px;
                object-fit: contain;
                margin-bottom: 50px;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: bold;
            margin-left: 10px;
            width: 90%;
            margin-top: 45px;
            font-size: 28px;
        }

        > h3 {
            font-weight: normal !important;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: larger;
            line-height: 1.8rem;
            width: 95%;

            > code {
                display: inline;
                width: 95%;
            }
        }
        
        > ul {
            margin-bottom: 35px;
            margin-top: 30px;
            font-weight: normal;
            font-size: larger;

            > li {
                font-weight: normal;
                margin-block: 35px;
                width: 90%;
                > strong {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`