import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Groups3Icon from '@mui/icons-material/Groups3';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SearchIcon from '@mui/icons-material/Search';
import ScienceIcon from '@mui/icons-material/Science';

const items = [
  {
    icon: <SearchIcon style={{marginRight: '10px'}}/>,
    title: 'Keyword Research',
    description:
      'Discover high-performing keywords, analyze search volume and CPC, and optimize your content for better search engine rankings.',
  },
  {
    icon: <Groups3Icon style={{marginRight: '10px'}}/>,
    title: 'Competition Research',
    description:
      'Analyze your competitors\' strategies, understand their strengths and weaknesses, and gain insights to stay ahead in your industry.',
  },
  {
    icon: <InsertLinkIcon style={{marginRight: '12px'}}/>,
    title: 'Backlink Analysis',
    description:
      'Examine your backlink profile, identify high-quality link opportunities, and track your link-building progress to enhance your SEO.',
  },
  {
    icon: <ScienceIcon style={{marginRight: '10px'}}/>,
    title: 'Website Testing Tools',
    description:
      'Utilize powerful testing tools to analyze and improve your website’s performance, usability, and speed for an optimal user experience.',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];



  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Product features
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Explore the comprehensive suite of tools designed to elevate your SEO strategies, streamline your content creation, and supercharge your business research.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            {selectedItemIndex === 0? <SearchIcon style={{margin: '10px'}}/>: selectedItemIndex === 1? <Groups3Icon style={{margin: '10px'}}/>: selectedItemIndex === 2? <InsertLinkIcon style={{margin: '10px'}}/>: <ScienceIcon style={{margin: '10px'}}/>}
            <h3 style={{padding: '10px', fontFamily: 'Crimson Text', fontWeight: '300'}}>

            {selectedItemIndex === 0 && <p style={{fontFamily: 'Crimson Text', marginBottom: '60px'}}>Unlock the full potential of your content with our advanced keyword research tools. Whether you are aiming to boost your blog traffic, enhance your ecommerce site visibility, or optimize your client’s website, our platform offers the insights you need. Discover high-performing keywords tailored to your niche and audience. Dive into comprehensive metrics, including search volumes, competition levels, and cost-per-click (CPC) data. Our intuitive interface not only helps you find the right keywords but also suggests relevant long-tail keywords to give you a competitive edge. Additionally, track keyword trends over time to stay ahead of shifts in search behavior. Our keyword research tools ensure you develop a robust SEO strategy that drives organic traffic and improves your search engine rankings.</p>}
            {selectedItemIndex === 0 && <SearchIcon style={{fontSize: '100px'}}/>}

            {selectedItemIndex === 1 && <p style={{fontFamily: 'Crimson Text', marginBottom: '60px'}}>Stay ahead of the curve with our comprehensive competitor analysis tools. Our platform empowers you to delve deep into your competitors’ digital strategies, uncovering key insights that can inform your own approach. Analyze competitors’ traffic sources to understand where their audience comes from, and identify which channels are most effective. Examine their top-performing keywords to see which terms drive their organic traffic, and explore their backlink profiles to discover valuable link-building opportunities. Furthermore, our tools allow you to monitor competitors’ content strategies, revealing what types of content resonate most with their audience. By understanding your competitors’ strengths and weaknesses, you can pinpoint market gaps and refine your strategies to outperform them. Gain the insights needed to make data-driven decisions and achieve a competitive advantage in your industry.</p>}
            {selectedItemIndex === 1 && <Groups3Icon style={{fontSize: '100px'}}/>}

            {selectedItemIndex === 2 && <p style={{fontFamily: 'Crimson Text', marginBottom: '60px'}}>Enhance your website’s authority and search engine rankings with our robust backlink analysis tools. Backlinks are crucial for SEO success, and our platform provides you with the tools to master this aspect. Conduct a thorough examination of your backlink profile to understand which links are driving the most value. Identify high-quality backlink opportunities to build a strong link network that enhances your site’s credibility. Our tools help you track your link-building progress over time, ensuring your efforts yield tangible results. Discover which backlinks are potentially harmful and disavow them to protect your site’s reputation. Additionally, analyze competitors’ backlink profiles to find link-building strategies that work. With our backlink analysis, you can develop and execute an effective link-building strategy that boosts your site’s authority and improves its position in search engine results</p>}
            {selectedItemIndex === 2 && <InsertLinkIcon style={{fontSize: '100px'}}/>}

            {selectedItemIndex === 3 && <p style={{fontFamily: 'Crimson Text', marginBottom: '60px'}}>Deliver an exceptional user experience with our advanced website testing tools. A high-performing, user-friendly website is essential for keeping visitors engaged and driving conversions. Our platform offers a comprehensive suite of testing tools designed to help you analyze and improve your site’s performance, usability, and speed. Conduct A/B tests to determine which design elements and content variations resonate most with your audience. Monitor page load times to ensure your site runs smoothly and efficiently, reducing bounce rates and improving user satisfaction. Identify and fix issues that could hinder your site’s performance, such as broken links, slow-loading pages, and usability flaws. Our website testing tools also provide insights into user behavior, allowing you to make data-driven improvements that enhance the overall user experience. By continuously optimizing your website, you can ensure it meets the needs of your visitors and achieves your business goals</p>}
            {selectedItemIndex === 3 && <ScienceIcon style={{fontSize: '100px', display: 'block'}}/>}
            </h3>
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
