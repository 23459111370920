import React, { useEffect } from 'react'
import styled from 'styled-components'
import BlogCard from './BlogCard'
import { posts } from './blog/posts'
import fr from '../media/francesca_2.png'
import ah from '../media/alv-2.png'
import { Helmet } from 'react-helmet-async'


const segmentArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

function Blog({ sWidth }) {
    const segmentedPosts = segmentArray(posts.sort((a, b) => b.n - a.n), 3);

    const mVPosts = posts.sort((a, b) => b.n - a.n)



      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "Blog",
            "name": "Improwth Blog",
            "description": "Explore the latest trends, tips, and tactics to elevate your digital game. Here, we turn industry insights into actionable strategies.",
            "url": "https://improwth.com/blog",
            "blogPost": posts.map(post => ({
                "@type": "BlogPosting",
                "headline": post.title,
                "description": post.desc,
                "author": {
                    "@type": "Person",
                    "name": post.author
                },
                "datePublished": post.date,
                "url": `https://improwth.com/blog/${post.title.toLowerCase().replaceAll(' ', '-')}`
            }))
        };


        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Discover cutting-edge strategies and expert tips on digital marketing, SEO, and more. Stay ahead of the curve with insightful articles and practical guides from the Improwth Blog."/>
        <title>Explore Our Latest Insights | Improwth Blog</title>
        <link rel="canonical" href="https://improwth.com/blog" />
    </Helmet>

    {sWidth > 768 ? <Container>
        <h1><span>Improwth</span> Blog</h1>

        <p>Explore the latest trends, tips, and tactics to elevate your digital game. Here, we turn industry insights into actionable strategies</p>
    
        <div style={{marginTop: '30px'}} />
        {segmentedPosts?.map(ps=> {
            return (<div>
            {ps.map(post=> {
                return (
                    <BlogCard
                        key={post.title}
                        cat = {post.cat}
                        title = {post.title} 
                        desc = {post.desc}
                        author = {post.author}
                        date = {post.date}
                    />
                )
            })}
        </div>)
        })}
    
    </Container>:
    <Posts style={{margin: '60px 5px 100px 5px'}}>
        <h1><span>Improwth</span> Blog</h1>
        {mVPosts.map(post => (
            <div style={{margin: '20px 5px'}}>
            <h2 onClick={()=> window.location.href = `/blog/${post.title.toLowerCase().replaceAll(' ', '-')}`}  style={{fontFamily: 'Quicksand', color: 'blue'}}>{post.title}</h2>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: '13px'}}>
                <img src={post.author === 'Francesca Romano'? fr: post.author === 'Alvaro Hernandez'? ah: ''} alt={post.author} style={{borderRadius: '50%'}} width={37} />
                <p style={{fontFamily: 'Quicksand', fontSize: 'small'}} onClick={() => window.location.href = `/authors/${post.author.toLowerCase().replaceAll(' ', '-')}`}>{post.author}</p>
                <p style={{marginLeft: 'auto'}}>{post.date}</p>
            </div>
            </div>
        ))}
    </Posts>
    }
    
    </>
  )
}

export default Blog


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 100px;

    > h1 {
        font-family: 'Quicksand';
        font-weight: 400;
        margin: 60px 0 60px 0;
        font-size: 50px;

        > span {
            font-family: 'Quicksand';
            color: blue;
        }
    }

    > p {
        font-family: 'Quicksand';
        font-size: larger;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        margin-block: 20px 20px;
        gap: 13px;
    }
`



const Posts = styled.div`
    display: flex;
    flex-direction: column !important;
    gap: 30px;
    margin-top: 60px;
    margin-bottom: 160px !important;


    > h1 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-bottom: 25px;
        width: 100%;
        margin-left: 25px;
        color: black;

        > span {
            font-weight: 300;
            font-family: 'Quicksand';
            color: blue;
        }
    }

    > div {
        > h2 {
            font-weight: 300;
            font-family: 'Quicksand';
        }
    }
`