import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import logo from '../media/MarketLab_logo_.png'
import HomeIcon from '@mui/icons-material/Home';
import CategorySharpIcon from '@mui/icons-material/CategorySharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';

import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import GrainIcon from '@mui/icons-material/Grain';
import { Helmet } from 'react-helmet-async';

const textEffect = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
`;

const StyledText = styled.p`
    font-size: 16px;
    animation: ${textEffect} 2s linear infinite alternate; /* Example animation applied to the component */
    font-family: 'Quicksand';
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: large;
    margin-top: 35px;
`;

function MLDocs({ sWidth }) {
    const [title, setTitle] = useState('Introduction')
    const [content, setContent] = useState('')

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    useEffect(()=> {
        if(title === 'Introduction') {
            setContent(<div className='jsx-content'>MarketLab offers a suite of integrated tools designed to empower users in understanding market dynamics, optimizing website performance, and gaining competitive insights. From detailed keyword research to comprehensive competitor analysis and advanced site testing capabilities, MarketLab is your go-to solution for driving digital success. Explore how MarketLab can transform your approach to online strategy and unlock new opportunities in the digital landscape.
            <br/><br/>
            MarketLab is a versatile suite of integrated tools meticulously crafted to empower users with deep insights into market dynamics, optimize website performance, and glean competitive advantages across digital landscapes. Whether you're delving into nuanced keyword research, conducting thorough competitor analyses, or fine-tuning site performance with advanced testing capabilities, MarketLab stands as your indispensable solution for navigating the complexities of online strategy. Discover how MarketLab not only transforms your approach to digital marketing but also unlocks new vistas of opportunity in today's competitive digital terrain.
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            <button onClick={()=> window.open('https://marketlab.improwth.com/register', '_blank')} className='btn'>Try MarketLab Now For Free</button>
            </div>)
        }
        if(title === 'Keyword Research') {
            setContent(<div className='jsx-content'>
                <h3>Overview</h3>
                <p>Keyword Research Tool provides comprehensive insights into search trends and user intent, essential for optimizing your content strategy and maximizing online visibility. Whether you're aiming to improve SEO rankings, refine PPC campaigns, or enhance content relevance, this tool equips you with essential data to make informed decisions.</p>
              
                <h3>Key Features</h3>
                <ul>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>Search Volume:</strong></div> <p>Understand the popularity and demand for specific keywords within your target market.</p></li>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>Average CPC (Cost Per Click):</strong></div> <p>Gauge the competitiveness and potential cost of running PPC campaigns for selected keywords.</p></li>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>Keyword Variations:</strong></div> <p>Explore related keywords and variations to expand your content reach and capture diverse search queries.</p></li>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>User Search Intent:</strong></div> <p>Analyze user behavior and intent behind search queries to align content with audience needs effectively.</p></li>
                </ul>
              
                <h3>Benefits</h3>
                <ul>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>Targeted Content Creation:</strong></div> <p> Tailor your content strategy based on real-time search data and user intent insights.</p></li>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>Enhanced PPC Campaigns:</strong> </div> <p>Optimize ad spend by targeting keywords with optimal CPC and high conversion potential.</p></li>
                  <li><div><RadioButtonUncheckedSharpIcon /><strong>Improved SEO Strategy:</strong> </div> <p>Increase organic traffic by focusing on keywords that resonate with your target audience.</p></li>
                </ul>
              </div>
              )
        }

        if(title === 'Competition Research') {
            setContent(<div className='jsx-content'>
                <h3>Overview</h3>
                <p>Competition Research Tool provides insights into competitors' strategies and market positioning, enabling you to refine your own approach and gain a competitive edge. Analyze organic ranking competitors for specific keywords and assess their page authority, domain authority, backlinks, dofollow links, and domains.</p>
              
                <h3>Key Features</h3>
                <ul>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Organic Ranking Competitors:</strong></div>
                        <p>View competitors ranking organically for specific keywords.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Page Authority (PA):</strong></div>
                        <p>Assess the strength and relevance of competitors' individual pages.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Domain Authority (DA):</strong></div>
                        <p>Evaluate the overall authority and influence of competitors' domains.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Backlinks:</strong></div>
                        <p>Explore the number of backlinks pointing to competitors' websites.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Dofollow Links:</strong></div>
                        <p>Identify the number of dofollow links that contribute to competitors' SEO efforts.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Domains:</strong></div>
                        <p>See the number of domains linking to competitors' websites.</p>
                    </li>
                </ul>
              
                <h3>Benefits</h3>
                <ul>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Refined Marketing Strategy:</strong></div>
                        <p>Develop targeted strategies based on insights into competitors' strengths and weaknesses.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Benchmarking:</strong></div>
                        <p>Compare your website's performance against competitors to identify opportunities for improvement.</p>
                    </li>
                    <li>
                        <div><RadioButtonUncheckedSharpIcon /><strong>Enhanced Decision Making:</strong></div>
                        <p>Make informed decisions on content optimization and marketing campaigns based on comprehensive competitor analysis.</p>
                    </li>
                </ul>
            </div>
            )
        }

        if (title === 'Google SERP') {
            setContent(
                <div className='jsx-content'>
                    <h3>Overview</h3>
                    <p>Google SERP Tool provides comprehensive insights into search engine results pages (SERPs) for any keyword by location. Analyze top-ranking websites and understand the domain and page authority requirements to effectively compete in search engine rankings.</p>
        
                    <h3>Key Features</h3>
                    <ul>
                        <li>
                            <div><RadioButtonUncheckedSharpIcon /><strong>Keyword-specific SERPs:</strong></div>
                            <p>View detailed search engine results pages (SERPs) tailored to specific keywords.</p>
                        </li>
                        <li>
                            <div><RadioButtonUncheckedSharpIcon /><strong>Location-based Analysis:</strong></div>
                            <p>Analyze SERPs based on geographical location to understand regional search trends.</p>
                        </li>
                        <li>
                            <div><RadioButtonUncheckedSharpIcon /><strong>Domain and Page Authority Requirements:</strong></div>
                            <p>Determine the necessary domain and page authority metrics to rank effectively for targeted keywords.</p>
                        </li>
                    </ul>
        
                    <h3>Benefits</h3>
                    <ul>
                        <li>
                            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Optimization:</strong></div>
                            <p>Optimize your SEO strategy by understanding the competitive landscape on search engine results pages.</p>
                        </li>
                        <li>
                            <div><RadioButtonUncheckedSharpIcon /><strong>Keyword Strategy Refinement:</strong></div>
                            <p>Refine keyword targeting based on insights from top-ranking SERPs and competitor analysis.</p>
                        </li>
                        <li>
                            <div><RadioButtonUncheckedSharpIcon /><strong>Localized Marketing Insights:</strong></div>
                            <p>Gain localized marketing insights by analyzing SERPs specific to different geographical areas.</p>
                        </li>
                    </ul>
                </div>
            );
        }
        
        if (title === 'Bing SERP') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Bing SERP Analysis Tool provides insights into search engine rankings and performance metrics specific to Bing search results. Whether you're targeting a demographic that prefers Bing or looking to diversify your search engine optimization (SEO) strategy beyond Google, this tool equips you with essential data.</p>
  
    <h3>Key Features</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Top Bing SERPs:</strong></div>
            <p>View top search engine results pages (SERPs) for specific keywords on Bing to understand ranking factors and competition.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Domain and Page Authority:</strong></div>
            <p>Assess the authority required for domains and pages to rank effectively on Bing search results.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Keyword Analysis:</strong></div>
            <p>Analyze keyword performance and trends within Bing's search ecosystem to refine your SEO strategy.</p>
        </li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Diversified SEO Strategy:</strong></div>
            <p>Expand your reach by optimizing for Bing search, tapping into a different audience segment.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Insights:</strong></div>
            <p>Gain a competitive edge by understanding Bing's unique ranking algorithms and competitor positioning.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Market Diversity:</strong></div>
            <p>Expand your audience reach by optimizing for Bing, catering to users who prefer this search engine and enhancing your potential customer acquisition across diverse markets. Reach users who prefer Bing as their primary search engine, enhancing market diversity and potential customer acquisition.</p>
        </li>
    </ul>
</div>

            );
        }
        
        if(title === 'Top Search Queries') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Top Search Queries tool provides insights into the most frequently searched keywords for a specified website. Understanding these top queries helps you identify popular topics and interests among your audience, enabling you to tailor your content strategy effectively.</p>
  
    <h3>Key Features</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Keyword Insights:</strong></div>
            <p>Discover the top searched keywords driving traffic to your website.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Trending Topics:</strong></div>
            <p>Identify current trends and popular topics relevant to your audience.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>User Engagement:</strong></div>
            <p>Evaluate user interest and engagement levels based on search query volumes.</p>
        </li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Content Optimization:</strong></div>
            <p>Optimize your content strategy by focusing on topics that resonate with your audience.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Strategic Planning:</strong></div>
            <p>Plan marketing campaigns and content creation efforts based on real-time keyword insights.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Analysis:</strong></div>
            <p>Compare your top search queries with competitors to uncover unique opportunities.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Indexed Pages') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Indexed Pages tool provides insights into the number of web pages indexed by both Google and Bing for a specified website. Understanding the indexation status helps you assess the visibility and crawlability of your content on these search engines.</p>
  
    <h3>Key Features</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Google Index:</strong></div>
            <p>View the total number of pages indexed by Google for your website.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Bing Index:</strong></div>
            <p>See the total number of pages indexed by Bing for your website.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Comparison:</strong></div>
            <p>Compare indexation between Google and Bing to understand visibility differences.</p>
        </li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Performance:</strong></div>
            <p>Monitor and improve SEO performance based on indexed pages on major search engines.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Content Crawlability:</strong></div>
            <p>Ensure all relevant pages are indexed to enhance content discoverability.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Search Engine Visibility:</strong></div>
            <p>Enhance overall search engine visibility by optimizing indexation across platforms.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Sitemaps') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Sitemaps tool provides insights into the sitemap of a specified website, including its URL, status, type, number of entries, and visibility settings. Sitemaps are crucial for search engine optimization (SEO) as they help search engines crawl and index website content efficiently.</p>
  
    <h3>Key Features</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Sitemap URL:</strong></div>
            <p>Retrieve the URL of the sitemap for the specified website.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Status:</strong></div>
            <p>Check the current status of the sitemap (e.g., active, pending).</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Type:</strong></div>
            <p>Identify the type of sitemap (e.g., XML, HTML).</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Entries:</strong></div>
            <p>View the number of entries or URLs included in the sitemap.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Visibility:</strong></div>
            <p>Check visibility settings related to search engines (e.g., public, private).</p>
        </li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Optimization:</strong></div>
            <p>Ensure sitemaps are correctly configured to improve website indexing and search engine visibility.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Monitoring and Management:</strong></div>
            <p>Monitor sitemap status and entries for maintenance and updates.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Enhanced Crawling:</strong></div>
            <p>Facilitate efficient crawling and indexing of website content by search engines.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Crawlability Test') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Crawlability Test tool evaluates whether a specified URL is crawlable and indexable by search engines. Ensuring crawlability and indexability is crucial for search engine optimization (SEO) to improve website visibility and accessibility in search engine results pages (SERPs).</p>
  
    <h3>Key Features</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Crawlable?</strong></div>
            <p>Check if the specified URL can be crawled by search engine bots.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Indexable?</strong></div>
            <p>Determine if the specified URL is eligible to be indexed and displayed in search engine results.</p>
        </li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Optimization:</strong></div>
            <p>Ensure URLs are optimized for search engine crawlers to enhance website visibility.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Enhanced Site Accessibility:</strong></div>
            <p>Improve accessibility of web content by verifying crawlability and indexability status.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Diagnostic Insights:</strong></div>
            <p>Gain insights into potential issues affecting search engine crawling and indexing.</p>
        </li>
    </ul>

    <h3>Google Crawl Rule</h3>
    <ul>
        <li>
            <strong>Allow:</strong> Specify rules for Google's crawler.
        </li>
    </ul>

    <h3>Bing Crawl Rule</h3>
    <ul>
        <li>
            <strong>Allow:</strong> Specify rules for Bing's crawler.
        </li>
    </ul>

    <h3>Google Index Rule</h3>
    <ul>
        <li>
            <strong>None</strong> - Indicates that there are no specific rules for indexing by Google.
        </li>
    </ul>

    <h3>Bing Index Rule</h3>
    <ul>
        <li>
            <strong>None</strong> - Indicates that there are no specific rules for indexing by Bing.
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Mobile Support Test') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Mobile Support Test tool assesses whether a website is optimized for mobile devices. With increasing mobile internet usage, ensuring mobile compatibility is essential for providing a seamless user experience across different devices.</p>
  
    <h3>Key Features</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Mobile Compatibility:</strong></div>
            <p>Determine if the website supports optimal display and functionality on mobile devices.</p>
        </li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Enhanced User Experience:</strong></div>
            <p>Ensure visitors have a positive experience regardless of the device they use.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Performance:</strong></div>
            <p>Improve search engine rankings with mobile-friendly websites favored in mobile search results.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Broadened Audience Reach:</strong></div>
            <p>Reach a wider audience by catering to users who primarily access the internet through mobile devices.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Headers Test') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Headers Test tool analyzes HTTP headers of a specified URL, providing insights into server responses and header configurations.</p>
  
    <h3>Functionality</h3>
    <p>The tool fetches and displays key HTTP headers returned by the entered URL, including:</p>
  
    <ul>
        <li><strong>Name:</strong> The name of the HTTP header.</li>
        <li><strong>Value:</strong> The value associated with the header.</li>
        <li><strong>Status:</strong> The HTTP status code returned by the server.</li>
        <li><strong>Date:</strong> The date and time when the HTTP response was generated.</li>
        <li><strong>Server:</strong> The type and version of the server software.</li>
        <li><strong>X-Frame-Options:</strong> Security measure to control if and how a page can be loaded in an iframe.</li>
        <li><strong>Location:</strong> Redirect location if the server responds with a 3xx status code.</li>
        <li><strong>Content-Length:</strong> The size of the response body in bytes.</li>
        <li><strong>Content-Type:</strong> The MIME type of the response body.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Diagnosis and Troubleshooting:</strong></div>
            <p>Identify server-side issues and troubleshoot HTTP response anomalies.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Security Assessment:</strong></div>
            <p>Review security-related headers like X-Frame-Options to prevent clickjacking attacks.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Performance Optimization:</strong></div>
            <p>Analyze cache-control and compression-related headers to optimize website performance.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Speed Test') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Speed Test tool evaluates the loading speed of a specified URL, providing detailed analysis based on user selection for desktop or mobile devices.</p>
  
    <h3>Functionality</h3>
    <p>The tool measures and displays:</p>
  
    <ul>
        <li><strong>Load Speed:</strong> The time taken for the URL to fully load on the selected device (desktop or mobile).</li>
        <li><strong>Detailed Analysis:</strong> Insights into various performance metrics, such as:</li>
        <ul>
            <li>First Contentful Paint (FCP): Time when the first content appears on the screen.</li>
            <li>Time to Interactive (TTI): Time taken for the page to become fully interactive.</li>
            <li>Total Blocking Time (TBT): Amount of time the main thread is blocked and unable to respond to user input.</li>
            <li>Cumulative Layout Shift (CLS): Measure of visual stability of the page elements.</li>
        </ul>
        <li><strong>Comparison:</strong> Optionally compare the load speed against industry benchmarks or competitor websites.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Performance Optimization:</strong></div>
            <p>Identify performance bottlenecks and optimize load times to enhance user experience and SEO rankings.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Device-Specific Insights:</strong></div>
            <p>Understand how load times vary between desktop and mobile devices to prioritize improvements.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Analysis:</strong></div>
            <p>Compare your website's speed against competitors to stay competitive in the digital landscape.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Link Analysis') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Link Analysis tool provides comprehensive insights into the backlink profile of a specified URL, helping users understand linking domains, anchor text distribution, and the overall authority of inbound links.</p>
  
    <h3>Functionality</h3>
    <p>The tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Backlink Overview:</strong> Detailed analysis of the quantity and quality of backlinks pointing to the specified URL.</li>
        <li><strong>Linking Domains:</strong> Number of unique domains linking to the specified URL.</li>
        <li><strong>Anchor Text Distribution:</strong> Breakdown of anchor texts used in backlinks, highlighting keyword variations and distribution.</li>
        <li><strong>Link Authority:</strong> Evaluation of the authority and trustworthiness of linking domains based on metrics like Domain Authority (DA) and Page Authority (PA).</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Insights:</strong></div>
            <p>Gain insights into your website's backlink profile to inform SEO strategies and improve organic search rankings.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitor Analysis:</strong></div>
            <p>Compare your backlink profile with competitors to identify opportunities for acquiring high-quality backlinks.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Performance Monitoring:</strong></div>
            <p>Track changes in backlink metrics over time to monitor the effectiveness of link-building efforts.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Keyword Density') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Keyword Density tool provides comprehensive insights into the percentage of times a keyword or key phrase appears on a webpage compared to the total number of words on the page. It helps users understand how well-balanced their content is in terms of keyword usage and ensures that it aligns with modern SEO best practices.</p>
  
    <h3>Functionality</h3>
    <p>The tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Keyword Frequency:</strong> Calculates how often a specified keyword or key phrase appears within the content.</li>
        <li><strong>Density Analysis:</strong> Provides a percentage breakdown of keyword density in relation to the total word count on the page.</li>
        <li><strong>Keyword Variations:</strong> Identifies related keywords and variations used within the content.</li>
        <li><strong>Content Optimization:</strong> Recommends adjustments to maintain a balanced keyword density that enhances content relevance without keyword stuffing.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Optimization:</strong></div>
            <p>Optimize content for SEO by ensuring appropriate keyword density that enhances visibility without negatively impacting user experience.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Content Quality:</strong></div>
            <p>Improve the quality of content by maintaining a natural flow of keywords and key phrases that resonate with target audiences.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Performance Monitoring:</strong></div>
            <p>Monitor keyword usage over time to adapt to changing SEO trends and algorithms for sustained search engine visibility.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Extract Meta Tags') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>Extract Meta Tags tool retrieves and displays metadata information from a specified URL, providing insights into how web pages are presented and interpreted by search engines and social media platforms.</p>
  
    <h3>Functionality</h3>
    <p>The tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Meta Title:</strong> Retrieves the title of the webpage, which is displayed on search engine results pages (SERPs) and browser tabs.</li>
        <li><strong>Meta Description:</strong> Fetches the meta description, a brief summary of the webpage's content, crucial for improving click-through rates on SERPs.</li>
        <li><strong>Meta Keywords:</strong> Retrieves meta keywords, although less used by modern search engines, they still provide insights into content themes.</li>
        <li><strong>Meta Robots:</strong> Indicates how search engine crawlers should interact with the webpage, influencing indexing and visibility.</li>
        <li><strong>Open Graph Tags:</strong> Extracts Open Graph meta tags used by social media platforms to display content when shared, enhancing visibility on social networks.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Optimization:</strong></div>
            <p>Improve SEO by ensuring accurate and compelling meta tags that enhance search engine visibility and click-through rates.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Social Media Engagement:</strong></div>
            <p>Enhance social media sharing by optimizing Open Graph meta tags, improving content visibility and engagement on platforms like Facebook, Twitter, and LinkedIn.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Content Strategy:</strong></div>
            <p>Gain insights into competitors' meta tags and adjust content strategy to differentiate and optimize metadata for better performance.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'High Quality Backlinks') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>The High Quality Backlinks tool identifies and evaluates links from reputable websites with relevant content and high domain authority. These backlinks significantly impact a website's search engine rankings, credibility, and authority in the digital landscape.</p>
  
    <h3>Functionality</h3>
    <p>The tool provides the following functionalities:</p>
  
    <ul>
        <li><strong>Backlink Source:</strong> Identifies the source of high-quality backlinks pointing to the specified URL or domain.</li>
        <li><strong>Relevance:</strong> Assesses the relevance of backlink sources to ensure they align with the website's content and industry.</li>
        <li><strong>Domain Authority (DA):</strong> Evaluates the authority and trustworthiness of domains linking to the website, influencing SEO performance.</li>
        <li><strong>Anchor Text:</strong> Analyzes anchor texts used in backlinks, providing insights into keyword relevancy and SEO strategy.</li>
        <li><strong>Link Quality:</strong> Determines the quality of backlinks based on factors such as source credibility and editorial context.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Enhanced SEO Performance:</strong></div>
            <p>Improve search engine rankings by acquiring high-quality backlinks from authoritative sources.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Increased Website Credibility:</strong></div>
            <p>Build trust and credibility with search engines and users by earning links from reputable websites.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Advantage:</strong></div>
            <p>Gain a competitive edge in organic search results by leveraging high-quality backlinks that enhance domain authority and visibility.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Low Quality Backlinks') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>The Low Quality Backlinks tool identifies and evaluates links from websites with poor reputation, irrelevant content, or low domain authority. These backlinks can negatively impact a website's search engine rankings, credibility, and overall SEO performance.</p>
  
    <h3>Functionality</h3>
    <p>The tool provides the following functionalities:</p>
  
    <ul>
        <li><strong>Backlink Source:</strong> Identifies and lists sources of low-quality backlinks pointing to the specified URL or domain.</li>
        <li><strong>Relevance:</strong> Assesses the relevance of backlink sources to the website's content and industry, identifying mismatches.</li>
        <li><strong>Domain Authority (DA):</strong> Evaluates the authority and trustworthiness of domains linking to the website, highlighting those with low authority.</li>
        <li><strong>Anchor Text:</strong> Analyzes anchor texts used in backlinks, revealing potentially spammy or irrelevant keywords.</li>
        <li><strong>Link Quality:</strong> Determines the quality of backlinks based on factors such as source credibility and context.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Improved Backlink Profile:</strong></div>
            <p>Maintain a healthy backlink profile by identifying and removing low-quality backlinks that could harm SEO efforts.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Enhanced SEO Performance:</strong></div>
            <p>Boost search engine rankings and credibility by eliminating links from unreliable or irrelevant websites.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Avoid Penalties:</strong></div>
            <p>Prevent potential penalties from search engines by ensuring your backlink profile adheres to quality guidelines.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'New Backlinks') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>The New Backlinks tool tracks and reports on newly acquired backlinks for your website. It provides valuable insights into your site's SEO health and identifies potential sources of traffic and authority.</p>
  
    <h3>Functionality</h3>
    <p>The tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Monitoring:</strong> Continuously monitors and logs newly discovered backlinks pointing to your website.</li>
        <li><strong>Reporting:</strong> Generates reports detailing the source, quality, and context of each new backlink.</li>
        <li><strong>Analytics:</strong> Provides analytics on the impact of new backlinks on your site's SEO performance and traffic metrics.</li>
        <li><strong>Alerts:</strong> Sends notifications or alerts when significant new backlinks are detected, allowing proactive management.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Insights:</strong></div>
            <p>Gain insights into your site's backlink profile and understand how new links contribute to SEO rankings and authority.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Traffic Source Identification:</strong></div>
            <p>Identify new sources of traffic and potential partnerships through acquired backlinks.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Advantage:</strong></div>
            <p>Stay ahead by monitoring competitors' new backlinks and adapting your SEO strategy accordingly.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Top Referrers') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>The Top Referrers tool identifies and analyzes the top sources referring traffic to your website. It provides insights into traffic sources, facilitating strategic decisions for partnerships and content placement to optimize traffic and engagement.</p>
  
    <h3>Functionality</h3>
    <p>This tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Referrer Identification:</strong> Identifies and lists the top websites or platforms driving traffic to your site.</li>
        <li><strong>Analytics:</strong> Provides analytics on traffic volume, quality, and engagement metrics from each referrer.</li>
        <li><strong>Comparison:</strong> Compares referrer performance over time to track trends and effectiveness.</li>
        <li><strong>Integration:</strong> Integrates with other analytics tools for comprehensive traffic analysis and optimization.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Strategic Insights:</strong></div>
            <p>Understand the effectiveness of different traffic sources and optimize content placement and partnerships accordingly.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Partnership Opportunities:</strong></div>
            <p>Identify potential partnerships with top referrers to enhance traffic and engagement.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Content Optimization:</strong></div>
            <p>Optimize content strategy by focusing on channels driving the most relevant and high-quality traffic.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Page Authority') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>The Page Authority tool assesses and predicts a webpage's likelihood to rank in search engine results based on factors such as backlinks and domain authority. It provides insights into a page's competitiveness and helps optimize SEO strategies to enhance organic traffic.</p>
  
    <h3>Functionality</h3>
    <p>This tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Page Score:</strong> Calculates and displays a score from 0 to 100, indicating the page's ranking potential.</li>
        <li><strong>Factors:</strong> Analyzes factors influencing page authority, including backlinks, domain authority, and content relevance.</li>
        <li><strong>Comparison:</strong> Compares page authority scores with competitors to identify strengths and areas for improvement.</li>
        <li><strong>Trend Analysis:</strong> Tracks changes in page authority over time to monitor SEO performance.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Optimization:</strong></div>
            <p>Optimize SEO strategies by understanding a page's competitiveness and focusing efforts on high-ranking potential keywords.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Organic Traffic Enhancement:</strong></div>
            <p>Enhance organic traffic by improving page authority through effective backlinking and content optimization.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Analysis:</strong></div>
            <p>Compare page authority with competitors to benchmark performance and refine SEO tactics accordingly.</p>
        </li>
    </ul>
</div>

            )
        }

        if(title === 'Domain Authority') {
            setContent(
                <div className='jsx-content'>
    <h3>Overview</h3>
    <p>The Domain Authority tool assesses and predicts a website's ranking potential on search engine result pages (SERPs) based on various factors. It helps gauge overall SEO performance and competitiveness in search rankings.</p>
  
    <h3>Functionality</h3>
    <p>This tool offers the following functionalities:</p>
  
    <ul>
        <li><strong>Domain Score:</strong> Calculates and displays a score from 0 to 100, indicating the website's ranking potential.</li>
        <li><strong>Factors:</strong> Analyzes factors influencing domain authority, including backlink profile, domain age, and overall trustworthiness.</li>
        <li><strong>Comparison:</strong> Compares domain authority scores with competitors to identify strengths and areas for improvement.</li>
        <li><strong>Trend Analysis:</strong> Tracks changes in domain authority over time to monitor SEO performance.</li>
    </ul>
  
    <h3>Benefits</h3>
    <ul>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>SEO Performance Evaluation:</strong></div>
            <p>Evaluate overall SEO performance and identify areas for improvement based on domain authority insights.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Competitive Benchmarking:</strong></div>
            <p>Compare domain authority with competitors to gauge market competitiveness and refine SEO strategies.</p>
        </li>
        <li>
            <div><RadioButtonUncheckedSharpIcon /><strong>Strategic Decision Making:</strong></div>
            <p>Make informed decisions on SEO tactics and investments based on domain authority analysis.</p>
        </li>
    </ul>
</div>

            )
        }
    }, [title])


    useEffect(()=> {
        setDrawerOpen(false)
    }, [title])

  return (
    <>
    <Helmet>
        <meta name="description" content="Access detailed guides and resources for MarketLab. Find everything you need to effectively use our SEO tools, from setup to advanced features, and optimize your digital strategy."/>
        <title>MarketLab Documentation | Comprehensive Guides & Resources</title>
        <link rel="canonical" href="https://improwth.com/products/market-lab/docs" />
    </Helmet>

    {sWidth > 768 && <Container>
        <LeftContainer>
            <div>
                <div className='lc-title'><HomeIcon /> Getting Started</div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Introduction')} style={{color: title==='Introduction'? 'dodgerblue': ''}}>Introduction</p>
                </div>
            </div>

            <div style={{marginTop: '35px'}}>
                <div className='lc-title'><CategorySharpIcon /> Tools</div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Keyword Research')} style={{color: title==='Keyword Research'? 'dodgerblue': ''}}>Keyword Research</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Competition Research')} style={{color: title==='Competition Research'? 'dodgerblue': ''}}>Competition Research</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Google SERP')} style={{color: title==='Google SERP'? 'dodgerblue': ''}}>Google SERP</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Bing SERP')} style={{color: title==='Bing SERP'? 'dodgerblue': ''}}>Bing SERP</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Top Search Queries')} style={{color: title==='Top Search Queries'? 'dodgerblue': ''}}>Top Search Queries</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Indexed Pages')} style={{color: title==='Indexed Pages'? 'dodgerblue': ''}}>Indexed Pages</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Sitemaps')} style={{color: title==='Sitemaps'? 'dodgerblue': ''}}>Sitemaps</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Crawlability Test')} style={{color: title==='Crawlability Test'? 'dodgerblue': ''}}>Crawlability Test</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Mobile Support Test')} style={{color: title==='Mobile Support Test'? 'dodgerblue': ''}}>Mobile Support Test</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Headers Test')} style={{color: title==='Headers Test'? 'dodgerblue': ''}}>Headers Test</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Speed Test')} style={{color: title==='Speed Test'? 'dodgerblue': ''}}>Speed Test</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Link Analysis')} style={{color: title==='Link Analysis'? 'dodgerblue': ''}}>Link Analysis</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Keyword Density')} style={{color: title==='Keyword Density'? 'dodgerblue': ''}}>Keyword Density</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Extract Meta Tags')} style={{color: title==='Extract Meta Tags'? 'dodgerblue': ''}}>Extract Meta Tags</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('High Quality Backlinks')} style={{color: title==='High Quality Backlinks'? 'dodgerblue': ''}}>High Quality Backlinks</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Low Quality Backlinks')} style={{color: title==='Low Quality Backlinks'? 'dodgerblue': ''}}>Low Quality Backlinks</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('New Backlinks')} style={{color: title==='New Backlinks'? 'dodgerblue': ''}}>New Backlinks</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Top Referrers')} style={{color: title==='Top Referrers'? 'dodgerblue': ''}}>Top Referrers</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Page Authority')} style={{color: title==='Page Authority'? 'dodgerblue': ''}}>Page Authority</p>
                </div>
                <div className='lc-content'>
                    <p onClick={()=> setTitle('Domain Authority')} style={{color: title==='Domain Authority'? 'dodgerblue': ''}}>Domain Authority</p>
                </div>
            </div>
            
        </LeftContainer>

        <RightContainer>
            <div>
                <img src={logo} />
                <h2>{title}</h2>
            </div>

            <div className='content' style={{overflow: 'hidden'}}>
                {content}
            </div>
            
        </RightContainer>
    </Container>}

    {sWidth <= 768 && (
                <MobileContainer>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} style={{width: '100%'}} className='icon-button'>
                        <div style={{fontFamily: 'Quicksand', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', marginBottom: '50px'}} className='topics'>
                        <StyledText><GrainIcon /> Topics</StyledText>
                        </div>
                    </IconButton>
                    <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
                    <LeftContainerMobile>
                <div style={{marginBlock: '60px'}} />
                <div>
                    <div className='lc-title'><HomeIcon /> Getting Started</div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Introduction'); toggleDrawer(false); }} style={{ color: title === 'Introduction' ? 'dodgerblue' : '' }}>Introduction</p>
                    </div>
                </div>

                <div style={{ marginTop: '35px' }}>
                    <div className='lc-title'><CategorySharpIcon /> Tools</div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Keyword Research'); toggleDrawer(false); }} style={{ color: title === 'Keyword Research' ? 'dodgerblue' : '' }}>Keyword Research</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Competition Research'); toggleDrawer(false); }} style={{ color: title === 'Competition Research' ? 'dodgerblue' : '' }}>Competition Research</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Google SERP'); toggleDrawer(false); }} style={{ color: title === 'Google SERP' ? 'dodgerblue' : '' }}>Google SERP</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Bing SERP'); toggleDrawer(false); }} style={{ color: title === 'Bing SERP' ? 'dodgerblue' : '' }}>Bing SERP</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Top Search Queries'); toggleDrawer(false); }} style={{ color: title === 'Top Search Queries' ? 'dodgerblue' : '' }}>Top Search Queries</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Indexed Pages'); toggleDrawer(false); }} style={{ color: title === 'Indexed Pages' ? 'dodgerblue' : '' }}>Indexed Pages</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Sitemaps'); toggleDrawer(false); }} style={{ color: title === 'Sitemaps' ? 'dodgerblue' : '' }}>Sitemaps</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Crawlability Test'); toggleDrawer(false); }} style={{ color: title === 'Crawlability Test' ? 'dodgerblue' : '' }}>Crawlability Test</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Mobile Support Test'); toggleDrawer(false); }} style={{ color: title === 'Mobile Support Test' ? 'dodgerblue' : '' }}>Mobile Support Test</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Headers Test'); toggleDrawer(false); }} style={{ color: title === 'Headers Test' ? 'dodgerblue' : '' }}>Headers Test</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Speed Test'); toggleDrawer(false); }} style={{ color: title === 'Speed Test' ? 'dodgerblue' : '' }}>Speed Test</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Link Analysis'); toggleDrawer(false); }} style={{ color: title === 'Link Analysis' ? 'dodgerblue' : '' }}>Link Analysis</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Keyword Density'); toggleDrawer(false); }} style={{ color: title === 'Keyword Density' ? 'dodgerblue' : '' }}>Keyword Density</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Extract Meta Tags'); toggleDrawer(false); }} style={{ color: title === 'Extract Meta Tags' ? 'dodgerblue' : '' }}>Extract Meta Tags</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('High Quality Backlinks'); toggleDrawer(false); }} style={{ color: title === 'High Quality Backlinks' ? 'dodgerblue' : '' }}>High Quality Backlinks</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Low Quality Backlinks'); toggleDrawer(false); }} style={{ color: title === 'Low Quality Backlinks' ? 'dodgerblue' : '' }}>Low Quality Backlinks</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('New Backlinks'); toggleDrawer(false); }} style={{ color: title === 'New Backlinks' ? 'dodgerblue' : '' }}>New Backlinks</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Top Referrers'); toggleDrawer(false); }} style={{ color: title === 'Top Referrers' ? 'dodgerblue' : '' }}>Top Referrers</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Page Authority'); toggleDrawer(false); }} style={{ color: title === 'Page Authority' ? 'dodgerblue' : '' }}>Page Authority</p>
                    </div>
                    <div className='lc-content'>
                        <p onClick={() => { setTitle('Domain Authority'); toggleDrawer(false); }} style={{ color: title === 'Domain Authority' ? 'dodgerblue' : '' }}>Domain Authority</p>
                    </div>
                </div>
            </LeftContainerMobile>
                    </Drawer>
                    <RightContainer>
                    <div style={{textAlign: 'left'}}>
                        <img src={logo} alt='MarketLab Logo' />
                        <h2 style={{marginTop: '50px'}}>{title}</h2>
                    </div>

                    <div className='content' style={{width: '90%', textAlign: 'left', fontSize: 'large'}}>
                        {content}
                    </div>
                    </RightContainer>
                </MobileContainer>
            )}
    
    </>
  )
}

export default MLDocs


const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding-block: 60px 260px;
    overflow: hidden;
`



const LeftContainer = styled.div`
    width: 15%;
    border-right: 1px solid lightgray;

    > div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;


        > .lc-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            font-family: 'Quicksand';
            font-size: large;
        }


        > .lc-content {
            margin-left: 10px;
            margin-top: 20px;
            font-size: medium;
            font-family: 'Quicksand';

            > p {
                cursor: pointer;
                color: gray;
                font-family: 'Quicksand';

                transition: color 0.3s ease-in-out;

                &:hover {
                    color: darkgray;
                }
            }
        }
    }
`


const RightContainer = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;


    > div {
        width: 100%;
        display: flex;
        flex-direction: column;


        > img {
            object-fit: contain;
            width: 200px;
            align-self: center;
        }

        > h2 {
            margin-top: 30px;
            margin-left: 15px;
            font-weight: 400;
            width: 100%;
            font-family: 'Quicksand';
        }
    }

    > .content {
        font-family: 'Quicksand';
        margin: 25px 0px 0px 15px;
        width: 75%;
        height: 100%;

        > .jsx-content {
            overflow: hidden;
            height: 100%;
            font-family: 'Quicksand';

            > .btn {
                padding: 6px 12px;
                border-radius: 3px;
                font-family: 'Quicksand';
                cursor: pointer;
                border: none;
                background-color: darkblue;
                color: white;
                transition: background-color 0.3s ease-in-out;

                &:hover {
                    background-color: white;
                    color: darkblue;
                }

            }
            
            > h3, p {
                font-family: 'Quicksand';
            }

            > ul {
                margin-left: 20px;
                overflow: hidden;
            }

            > ul > li {
                font-family: 'Quicksand';
                font-size: 17px;
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;

                > div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                > div > .MuiSvgIcon-root {
                    font-size: small;
                    margin-right: 5px;
                }

                > div > p {
                    font-family: 'Quicksand';
                    margin-left: 10px;
                }
            }

            > ul > li > strong {
                font-weight: 400;
                color: dodgerblue;
                font-family: 'Quicksand';
            }

            > ul > li > div > strong {
                font-family: 'Quicksand';
                font-size: 17px;
                font-weight: 500;
                color: dodgerblue;
            }


            > p, ul {
                margin-block: 25px;
                font-size: 17px;
            }
        }
    }
`


const MobileContainer = styled.div`
    /* Mobile styles here */
    padding: 20px;
    text-align: center;


    > div {
        margin-bottom: 20px;

        > img {
            object-fit: contain;
            width: 150px;
        }

        > h2 {
            font-size: 1.5rem;
            margin-top: 20px;
            font-weight: 400;
            font-family: 'Quicksand';
        }
    }

    > .content {
        font-family: 'Quicksand';

        > .jsx-content {
            font-family: 'Quicksand';
            margin-top: 20px;

            > p {
                font-family: 'Quicksand';
                font-size: 1rem;
                line-height: 1.5;
            }
        }
    }
`;

const LeftContainerMobile = styled.div`
    /* display: flex;
    flex-direction: column;
    overflow-y: auto; */
    padding-bottom: 60px;
    width: 200px;

    > div {
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        > .lc-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            font-family: 'Quicksand';
            margin-bottom: 6px;
        }

        > .lc-content {
            font-family: 'Quicksand';

            > p {
                cursor: pointer;
                color: gray;
                font-family: 'Quicksand';
                margin-block: 8px;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: darkgray;
                }
            }
        }
    }
`;