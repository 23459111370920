import React, { useEffect } from 'react'
import styled from 'styled-components'
import logo from '../media/logo.png'
import a1 from '../media/top_clutch.co_seo_company_netherlands_2023.png'
import a2 from '../media/top_clutch.co_seo_company_amsterdam_2023.png'
import a3 from '../media/6.png'
import a4 from '../media/5.png'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Helmet } from 'react-helmet-async'


function About({ sWidth }) {




      useEffect(() => {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Improwth",
            "url": "https://improwth.com",
            "logo": "https://improwth.com/media/logo.png",
            "description": 'Learn more about Improwth, our mission, vision, and the products we offer',
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1 803 6927246",
                "contactType": "Customer Service",
                "email": "reach@improwth.com",
            },
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Amsterdam",
                "addressCountry": "Netherlands"
            }
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);


  return (
    <>
    <Helmet>
        <meta name="description" content="Discover Improwth’s mission to revolutionize digital marketing with cutting-edge SEO tools and strategies. Learn about our team, our values, and how we drive success for businesses through innovation and expertise."/>
        <title>About Us | Meet the Team Behind Improwth</title>
        <link rel="canonical" href="https://improwth.com/about" />
    </Helmet>

    {sWidth > 768 && <Container>
        <Hero>
            <div>
                <h1>About <span>Improwth</span></h1>
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={logo} alt='logo'/>
            </div>
        </Hero>

        <Content>
            <div>
                <h2>Company Overview</h2>
                <div>
                    <h3>Mission</h3>
                    <p>
                    Our mission is to empower businesses with cutting-edge marketing software solutions that drive growth and innovation. 
                    We aim to transform the digital landscape by providing tools that enhance marketing efforts and deliver measurable results.
                    </p>
                </div>

                <div>
                    <h3>Vision</h3>
                    <p>
                    Our vision is to become the leading provider of SaaS solutions in the marketing industry, 
                    helping businesses of all sizes achieve their goals with precision and efficiency.
                    </p>
                </div>

                <div>
                    <h3>Values</h3>
                    <p style={{width: '100%'}}>
                        <ul>
                            <li><p>Innovation:</p><span> We constantly innovate to stay ahead of industry trends and provide our clients with the most advanced tools.</span></li>                            
                            <li><p>Customer Success:</p><span> Our clients' success is our success. We are dedicated to providing exceptional support and ensuring our solutions deliver real value.</span></li>                            
                            <li><p>Integrity:</p><span> We operate with transparency and honesty in all our dealings, building trust with our clients and partners.</span></li>                            
                        </ul>
                    </p>
                </div>
            </div>


            <div>
                <h2>Our Story</h2>
                <div>
                    <h3>Founding</h3>
                    <p>
                    Improwth was founded in 2020 with a passion for SEO and a commitment to helping businesses enhance their online presence. 
                    Over the years, we have evolved to meet the growing needs of the digital marketing landscape.
                    </p>
                </div>

                <div>
                    <h3>Journey</h3>
                    <p>
                    Starting as a service-based business specializing in SEO, we recognized the potential to make a broader impact by developing software solutions. 
                    This shift has allowed us to scale our expertise and offer innovative tools that support various aspects of marketing.
                    </p>
                </div>
            </div>


            <div>
                <h2>Team</h2>
                <div>
                    <h3>Leadership</h3>
                    <p style={{width: '100%'}}>
                        <ul className='team'>
                            <li><p>Fahad Aldossary, CEO</p><span> Fahad's vision and leadership have been instrumental in transforming Improwth from a service-based business to a pioneering SaaS company.</span></li>                            
                            <li><p>Charlotte Miller, Sales Manager</p><span>Charlotte drives our sales strategies with a focus on building strong client relationships.</span></li>                            
                            <li><p>Alvaro Hernandez, CMO</p><span> Alvaro ensures our marketing strategies drive growth and brand presence, enabling us to invest in continuous innovation.</span></li>                            
                        </ul>
                    </p>
                </div>

                <div>
                    <h3>Staff</h3>
                    <p>
                    Our diverse team of experts includes developers, marketers, and customer support specialists, 
                    all dedicated to delivering the best possible solutions and support to our clients.
                    </p>
                </div>
            </div>



            <div>
                <h2>Products</h2>
                <div>
                    <h3>Overview</h3>
                    <p>
                    Improwth offers a suite of powerful SaaS products designed to enhance your marketing efforts and drive business growth.
                    </p>
                </div>

                <div>
                    <h3>Details</h3>
                    <p style={{width: '100%'}}>
                        <ul>
                            <li><p>MarketLab</p><span> An innovative platform that provides deep insights into digital markets, helping you decode the language of success.</span></li>                            
                          </ul>
                    </p>
                </div>
            </div>


            <div>
                <h2>Awards and Recognition</h2>
                <div>
                    <h3>Accolades:</h3>
                    <div className='a' style={{display: 'flex', flexDirection: 'row', gap: '0px', alignItems: 'center', justifyContent: 'left', marginBottom: '20px'}}>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a1} width={120} style={{display: 'block', marginBlock: '30px'}} alt='Top Clutch SEO Company in Netherlands 2023'/>
                    Top Clutch SEO Company in Netherlands 2023
                    </p>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a2} width={120} style={{display: 'block', marginBlock: '30px'}} alt='Top Clutch SEO Company in Amsterdam 2023'/>
                    Top Clutch SEO Company in Amsterdam 2023
                    </p>
                    </div>
                    <div className='a' style={{display: 'flex', flexDirection: 'row', gap: '0px', alignItems: 'center', justifyContent: 'left', marginBottom: '20px'}}>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a3} width={130} style={{display: 'block', marginBlock: '30px'}} alt='Best Agency Award (AAC, Netherlands) 2022'/>
                    Best Agency Award (AAC, Netherlands) 2022
                    </p>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a4} width={170} style={{display: 'block', marginBlock: '30px'}} alt='Best Remote Work Experience 2022'/>
                    Best Remote Work Experience 2022
                    </p>
                    </div>
                </div>
            </div>


            <div id='contact-us'>
                <h2>Contact Information</h2>
                <div>
                    <h3>Get in Touch</h3>
                    <p>
                    For inquiries, support, or partnership opportunities, please contact us at:  
                    </p>
                    <p><EmailIcon /> reach@improwth.com</p>
                    <p><PhoneIcon /> +1 803 6927246</p>
                    <p><WhatsAppIcon /> +1 540 614 0717</p>
                </div>

                <div>
                    <h3>Location</h3>
                    <p>
                    Our headquarters are located in Amsterdam, with a growing presence globally.
                    </p>
                </div>
            </div>
        </Content>
    </Container>}

    {sWidth <= 768 && 
    <MContainer>
            <MHero>
            <div>
                <h1>About <span>Improwth</span></h1>
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={logo} alt='logo'/>
            </div>

            <KeyboardArrowDownIcon />
        </MHero>



        <MContent>
            <div>
                <h2>Company Overview</h2>
                <div>
                    <h3>Mission</h3>
                    <p>
                    Our mission is to empower businesses with cutting-edge marketing software solutions that drive growth and innovation. 
                    We aim to transform the digital landscape by providing tools that enhance marketing efforts and deliver measurable results.
                    </p>
                </div>

                <div>
                    <h3>Vision</h3>
                    <p>
                    Our vision is to become the leading provider of SaaS solutions in the marketing industry, 
                    helping businesses of all sizes achieve their goals with precision and efficiency.
                    </p>
                </div>

                <div>
                    <h3>Values</h3>
                    <p style={{width: '100%'}}>
                        <ul>
                            <li><p>Innovation:</p><span> We constantly innovate to stay ahead of industry trends and provide our clients with the most advanced tools.</span></li>                            
                            <li><p>Customer Success:</p><span> Our clients' success is our success. We are dedicated to providing exceptional support and ensuring our solutions deliver real value.</span></li>                            
                            <li><p>Integrity:</p><span> We operate with transparency and honesty in all our dealings, building trust with our clients and partners.</span></li>                            
                        </ul>
                    </p>
                </div>
            </div>


            <div>
                <h2>Our Story</h2>
                <div>
                    <h3>Founding</h3>
                    <p>
                    Improwth was founded in 2020 with a passion for SEO and a commitment to helping businesses enhance their online presence. 
                    Over the years, we have evolved to meet the growing needs of the digital marketing landscape.
                    </p>
                </div>

                <div>
                    <h3>Journey</h3>
                    <p>
                    Starting as a service-based business specializing in SEO, we recognized the potential to make a broader impact by developing software solutions. 
                    This shift has allowed us to scale our expertise and offer innovative tools that support various aspects of marketing.
                    </p>
                </div>
            </div>


            <div>
                <h2>Team</h2>
                <div>
                    <h3>Leadership</h3>
                    <p style={{width: '100%'}}>
                        <ul className='team'>
                            <li><p>Fahad Aldossary, CEO</p><span> Fahad's vision and leadership have been instrumental in transforming Improwth from a service-based business to a pioneering SaaS company.</span></li>                            
                            <li><p>Charlotte Miller, Sales Manager</p><span>Charlotte drives our sales strategies with a focus on building strong client relationships.</span></li>                            
                            <li><p>Alvaro Hernandez, CMO</p><span> Alvaro ensures our marketing strategies drive growth and brand presence, enabling us to invest in continuous innovation.</span></li>                            
                        </ul>
                    </p>
                </div>

                <div>
                    <h3>Staff</h3>
                    <p>
                    Our diverse team of experts includes developers, marketers, and customer support specialists, 
                    all dedicated to delivering the best possible solutions and support to our clients.
                    </p>
                </div>
            </div>



            <div>
                <h2>Products</h2>
                <div>
                    <h3>Overview</h3>
                    <p>
                    Improwth offers a suite of powerful SaaS products designed to enhance your marketing efforts and drive business growth.
                    </p>
                </div>

                <div>
                    <h3>Details</h3>
                    <p style={{width: '100%'}}>
                        <ul>
                            <li><p>MarketLab</p><span> An innovative platform that provides deep insights into digital markets, helping you decode the language of success.</span></li>                            
                          </ul>
                    </p>
                </div>
            </div>


            <div>
                <h2>Awards and Recognition</h2>
                <div>
                    <h3>Accolades:</h3>
                    <div className='a' style={{display: 'flex', flexDirection: 'row', gap: '0px', alignItems: 'center', justifyContent: 'left', marginBottom: '20px'}}>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a1} width={120} style={{display: 'block', marginBlock: '30px'}} alt='Top Clutch SEO Company in Netherlands 2023'/>
                    Top Clutch SEO Company in Netherlands 2023
                    </p>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a2} width={120} style={{display: 'block', marginBlock: '30px'}} alt='Top Clutch SEO Company in Amsterdam 2023'/>
                    Top Clutch SEO Company in Amsterdam 2023
                    </p>
                    </div>
                    <div className='a' style={{display: 'flex', flexDirection: 'row', gap: '0px', alignItems: 'center', justifyContent: 'left', marginBottom: '20px'}}>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a3} width={130} style={{display: 'block', marginBlock: '30px'}} alt='Best Agency Award (AAC, Netherlands) 2022'/>
                    Best Agency Award (AAC, Netherlands) 2022
                    </p>
                    <p style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={a4} width={170} style={{display: 'block', marginBlock: '30px'}} alt='Best Remote Work Experience 2022'/>
                    Best Remote Work Experience 2022
                    </p>
                    </div>
                </div>
            </div>


            <div id='contact-us'>
                <h2>Contact Information</h2>
                <div>
                    <h3>Get in Touch</h3>
                    <p>
                    For inquiries, support, or partnership opportunities, please contact us at:  
                    </p>
                    <p><EmailIcon /> reach@improwth.com</p>
                    <p><PhoneIcon /> +1 803 6927246</p>
                    <p><WhatsAppIcon /> +1 540 614 0717</p>
                </div>

                <div>
                    <h3>Location</h3>
                    <p>
                    Our headquarters are located in Amsterdam, with a growing presence globally.
                    </p>
                </div>
            </div>
        </MContent>
    </MContainer>
    }
    </>
  )
}

export default About



const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Crimson Text';
`


const Hero = styled.div`
    display: flex;
    gap: 20px;
    padding-block: 60px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    > div {
        margin: 100px 0 0 35px;
        width: 50%;

        > h1 {
            font-family: 'Crimson Text';
            font-weight: 300;
            font-size: 60px;

            > span {
                font-family: 'Crimson Text';
                color: blue;
            }
        }

        > img {
            object-fit: contain;
            width: 300px;
        }
    }
`


const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding-block: 100px;
    padding-left: 20px;

    > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 120px;

        > h2 {
            font-weight: 300;
            font-family: 'Crimson Text';
            font-size: 35px;
            margin-bottom: 20px;
        }

        > div {
            display: flex;
            flex-direction: column;
            margin-block: 35px;

            > h3 {
                font-family: 'Crimson Text';
                font-weight: 300;
            }

            > p, .a > p {
                font-family: 'Crimson Text';
                margin-top: 20px;
                width: 80%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                > ul > li {
                    font-family: 'Crimson Text';
                    display: flex;

                    > p {
                        font-family: 'Crimson Text';
                        width: 160px;
                        font-weight: bold;
                    }

                    > span {
                        font-family: 'Crimson Text';
                    }
                }


                > .team > li {
                    font-family: 'Crimson Text';
                    display: flex;

                    > p {
                        font-family: 'Crimson Text';
                        width: 260px;
                        font-weight: bold;
                    }

                    > span {
                        font-family: 'Crimson Text';
                    }
                }
            }
        }
    }
`



////////////////////////



const MContainer = styled.div`
    display: flex;
    flex-direction: column;
`


const MHero = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-block: 30px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 100px;

    > div {
        margin: 40px 0 0 35px;
        width: 50%;

        > h1 {
            font-family: 'Crimson Text';
            font-weight: 300;
            font-size: 30px;

            > span {
                font-family: 'Crimson Text';
                color: blue;
                font-size: 40px;
            }
        }

        > img {
            object-fit: contain;
            width: 100px;
        }
    }

    > .MuiSvgIcon-root {
        color: var(--root);
        margin-top: 60px;
        align-self: start;
        margin-left: 25px;
        font-size: 37px;
    }
`


const MContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-block: 100px;
    padding-left: 20px;

    > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 120px;

        > h2 {
            font-weight: 300;
            font-family: 'Crimson Text';
            font-size: 35px;
            margin-bottom: 20px;
        }

        > div {
            display: flex;
            flex-direction: column;
            margin-block: 35px;

            > h3 {
                font-family: 'Crimson Text';
                font-weight: 300;
            }

            > .a {
                display: flex;
                flex-direction: column !important;
            }

            > p, .a > p {
                font-family: 'Crimson Text';
                margin-top: 20px;
                width: 80%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                font-size: small;

                > ul > li {
                    font-family: 'Crimson Text';
                    display: flex;
                    flex-direction: column;
                    font-size: small;
                    margin-block: 25px;

                    > p {
                        font-family: 'Crimson Text';
                        width: 160px;
                        font-weight: bold;
                    }

                    > span {
                        font-family: 'Crimson Text';
                    }
                }


                > .team > li {
                    font-family: 'Crimson Text';
                    display: flex;

                    > p {
                        font-family: 'Crimson Text';
                        width: 260px;
                        font-weight: bold;
                    }

                    > span {
                        font-family: 'Crimson Text';
                    }
                }
            }
        }
    }
`