import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import logo from '../media/logo2.png'
import mLogo from '../media/logo.png'
import { Link, useLocation } from 'react-router-dom'
import SegmentIcon from '@mui/icons-material/Segment';

function Header({ sWidth, path_listn }) {
    const [show, setShow] = useState(false)
    const linkStyles = {
        textDecoration: 'none'
    }


    const location = useLocation()

    useEffect(() => {
        setShow(false);
        path_listn()
    }, [location]);
    
  return (
    <>
    {sWidth > 768 && <Container>
        <LeftContainer>
            <img src={logo} alt='logo' onClick={()=> window.location.href = '/'}/>
        </LeftContainer>
        <RightContainer>
            <Link to='/products' style={linkStyles}><p>Products</p></Link>
            <Link to='/events' style={linkStyles}><p>Events</p></Link>
            <Link to='/blog' style={linkStyles}><p>Blog</p></Link>
            <Link to='/contact' style={linkStyles}><p>Contact us</p></Link>
            <Link to='/about' style={linkStyles}><p className='p'>About Us</p></Link>
        </RightContainer>
    </Container>}

    {sWidth <= 768 && 
    <MContainer>
        <img src={mLogo} alt='logo'onClick={()=> window.location.href = '/'}/>
        <SegmentIcon onClick={()=> setShow(prev=> !prev)}/>
        <DropDown show={show}>
            <Link to='/products' style={linkStyles}><p style={{color: 'white'}}>Products</p></Link>
            <Link to='/events' style={linkStyles}><p style={{color: 'white'}}>Events</p></Link>
            <Link to='/blog' style={linkStyles}><p style={{color: 'white'}}>Blog</p></Link>
            <Link to='/contact' style={linkStyles}><p style={{color: 'white'}}>Contact us</p></Link>
            <Link to='/about' style={linkStyles}><p className='p' style={{color: 'white'}}>About Us</p></Link>
        </DropDown>
    </MContainer>
    }
    </>
  )
}

export default Header


const Container = styled.div`
    display: flex;
    align-items: center;
`

const LeftContainer = styled.div`
    display: flex;
    align-items: center;

    > img {
        width: 260px;
        margin: 10px 0 0 10px;
        cursor: pointer;
    }
`


const RightContainer = styled.div`
    display: flex;
    align-items: center;
    width: 600px;
    margin: 10px 90px 0 auto;
    gap: 60px;
    justify-content: right;

    > .p, p {
        font-weight: 300;
        cursor: pointer;
        font-family: 'Crimson Text';
        color: black;

        &:hover {
            color: dodgerblue;
        }
    }
`


/////////////////


const MContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 7px 10px 7px;

    > img {
        object-fit: contain;
        width: 47px;
    }

    > .MuiSvgIcon-root {
        font-size: 30px;
        color: var(--root);
        position: relative;
    }
`

const slideFromAbove = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;


const DropDown = styled.div`
    display: ${props=> props.show? 'flex': 'none'};
    flex-direction: column;
    padding: 20px 10px 20px 10px;
    gap: 15px;
    color: white;
    background-color: var(--root);
    width: 200px;
    height: auto;
    border-radius: 3px;
    z-index: 10;

    position: absolute;
    right: 0;
    top: 60px;

    animation: ${slideFromAbove} 0.3s ease-out forwards;

    > p {
        color: white;
        height: fit-content;
    }
`