import React, { useEffect } from 'react'
import styled from 'styled-components'
import vid from '../vid/events.mp4'
import mVid from '../vid/mEvents.mp4'
import 'video-react/dist/video-react.css'
import ReactPlayer from 'react-player'
import { Helmet } from 'react-helmet-async'


function Events({ sWidth }) {



    useEffect(()=> {
      const schemaData = {
        "@context": "https://schema.org",
        "@type": "Event",
        "name": "Exclusive Digital Marketing Webinars",
        "startDate": "2024-09-20T19:00:00Z",
        "endDate": "2024-10-20T21:00:00Z",
        "location": {
            "@type": "Place",
            "name": "Online Event",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Online",
                "addressCountry": "US"
            }
        },
        "description": "Unlock the secrets to digital marketing success with our expertly curated webinars. Gain valuable insights, strategies, and tips from industry leaders to stay ahead of the curve.",
        "url": "https://improwth.com/events",
        "offers": {
            "@type": "Offer",
            "url": "mailto:reach@improwth.com?subject=Request%20to%20Join%20Digital%20Marketing%20Webinars",
            "name": "Register for Webinars",
            "price": "0",
            "priceCurrency": "USD"
        }
      };
  
      // Inject the schema data into the head
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(schemaData);
      document.head.appendChild(script);
  
      return () => {
          document.head.removeChild(script);
      };
    }, [])


  return (
    <>
    <Helmet>
        <meta name="description" content="Stay updated on Improwth’s latest digital marketing events and webinars. Discover valuable insights, trends, and strategies from industry experts. Register now to secure your spot and enhance your marketing skills."/>
        <title>Upcoming Events | Join Improwth’s Digital Marketing Webinars</title>
        <link rel="canonical" href="https://improwth.com/events" />
    </Helmet>

    {sWidth > 768 ? (
      <Container>
        <h1>Dive Into the Future of Digital Marketing—Join Our Exclusive Events!</h1>

        <video width="50%" controls controlsList="nodownload" type="video/mp4">
          <source src='https://firebasestorage.googleapis.com/v0/b/core-15b36.appspot.com/o/media%2Fevents.mp4?alt=media&token=dabcc670-6c4a-4bec-9e30-299533d999c2' type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* <div className='vid-container'>
          <ReactPlayer url={vid} controls width='100%' height='420px' style={{overflow: 'hidden'}}/>
        </div> */}
        

        <h2>Register for Our Exclusive Digital Marketing Webinars</h2>

        <p>Unlock the secrets to digital marketing success with our expertly curated webinars. Gain valuable insights, strategies, and tips from industry leaders to stay ahead of the curve. To secure your spot in our upcoming sessions, simply email us at <span style={{color: 'dodgerblue'}}>reach@improwth.com</span> with the subject line “<span style={{color: 'dodgerblue'}}>Request to Join Digital Marketing Webinars</span>”. Don’t miss out on this opportunity to enhance your digital marketing skills and drive impactful results for your business!</p>
      </Container>
    ) : (
      <MobileContainer>
        <h1>Join Our Exclusive Digital Marketing Events!</h1>

        <video width="60%" controls controlsList="nodownload" type="video/mp4">
          <source src='https://firebasestorage.googleapis.com/v0/b/core-15b36.appspot.com/o/media%2FmEvents.mp4?alt=media&token=0a27c81e-d51c-4306-bb51-9371782a2e5a' type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* <div className='vid-container'>
            <ReactPlayer url={mVid} controls width='100%' height='450px' style={{overflow: 'hidden'}}/>
        </div> */}


        <h2>Register Now!</h2>

        <p>Get valuable insights and tips from industry leaders. Email us at <span style={{color: 'dodgerblue'}}>reach@improwth.com</span> with the subject “<span style={{color: 'dodgerblue'}}>Request to Join Digital Marketing Webinars</span>” to register. Enhance your digital marketing skills with our expert webinars!</p>
      </MobileContainer>
    )}
    </>
  )
}

export default Events

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-weight: 400;
    font-family: 'Quicksand';
    margin: 60px 0 40px 15px;
  }

  > video {
    margin-bottom: 100px;
    border: 1px solid lightgray;
    border-radius: 3px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }

  > h2 {
    font-family: 'Quicksand';
    font-weight: 400;
    margin: 60px 0 20px 15px;
  }

  > p {
    margin: 30px 0 200px 15px;
    font-family: 'Quicksand';
    width: 60%;
  }

  > .vid-container {
    width: 50%;
    margin-bottom: 100px;
    border: 1px solid lightgray;
    border-radius: 3px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 420px;
  }
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-weight: 400;
    font-family: 'Quicksand';
    margin: 60px 0 20px 15px;
    font-size: 1.1rem;
  }

  > video {
    margin-bottom: 60px;
    border: 1px solid lightgray;
    border-radius: 3px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    margin-inline: 10px;
  }

  > h2 {
    font-family: 'Quicksand';
    font-weight: 400;
    margin: 30px 0 15px 15px;
    font-size: 1rem;
    align-self: flex-start;
  }

  > p {
    margin: 20px 0 80px 15px;
    font-family: 'Quicksand';
    width: 90%;
    font-size: 0.9rem;
    text-align: center;
    align-self: flex-start;
    text-align: left;
  }

  > .vid-container {
    width: 60%;
    margin-bottom: 100px;
    border: 1px solid lightgray;
    border-radius: 3px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    height: 450px;
  }
`
