import React from 'react'
import styled from 'styled-components'
import a1 from '../media/francesca_2.png'
import a2 from '../media/alv-2.png'

function BlogCard({ cat, title, desc, author, date, authorPage }) {
    let categories = cat.split('&')
  return (
    <Container>
        <label>{categories.map(c=> <span onClick={()=> window.location.href = `/blog/category/${c.replaceAll(' ', '-')}`}>{c}</span>)}</label>
        <h2 onClick={()=> window.location.href = `/blog/${title.toLowerCase().replaceAll(' ', '-')}`}>{title}</h2>

        <p onClick={()=> window.location.href = `/blog/${title.toLowerCase().replaceAll(' ', '-')}`}>{desc}</p>

        <div>
            <img src={author === 'Francesca Romano'? a1: author === 'Alvaro Hernandez'? a2: ''} width={40} height={40}/>
            <p className='author' onClick={() => window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</p>
            <p className='date'>{date}</p>
        </div>
    </Container>
  )
}

export default BlogCard


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); */
    width: 400px;
    height: 270px;
    

    > label {
        > span {
            color: gray;
            font-family: 'Quicksand';
            margin-right: 7px;

            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: blue;
            }
        }
    }

    > h2 {
        font-weight: 400;
        font-size: 24px;
        font-family: 'Quicksand';
        cursor: pointer;
        transition: color 0.3s ease-in-out;
        color: blue;

        &:hover {
            color: darkblue;
        }
    }

    > p {
        font-family: 'Quicksand';
        margin-block: 20px;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: blue;
        }
    }

    > div {
        display: flex;
        flex-direction: row;
        width: 100%;
        font-family: 'Quicksand';
        align-items: center;
        gap: 10px;
        margin-top: auto;


        > img {
            border-radius: 50%;
        }

        > p {
            font-family: 'Quicksand';
        }

        > .author {
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: blue;
            }
        }

        > .date {
            margin-left: auto;
            font-family: 'Quicksand';
        }
    }
`