import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import img1 from '../../../media/blog/comp_1.jpeg'


function MidCycleMarketingEmail({ sWidth, title, desc, author, date }) {
    const category = 'email marketing'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Unlock the potential of mid-cycle marketing emails! Learn how to nurture leads, address customer objections, and drive conversions with actionable strategies that work." />
        <title>What Is Mid-Cycle Marketing Emails | A Comprehensive Guide</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>
        In the lifecycle of a customer journey, the timing and content of your emails play a crucial role in nurturing leads, retaining customers, and driving conversions. One important, yet often underutilized, element of email marketing is the <strong>mid-cycle marketing email</strong>. Positioned between the initial awareness stages and the final conversion, this type of email helps maintain engagement, address doubts, and keep your brand top of mind.
      </p>

      <h2>What Is a Mid-Cycle Marketing Email?</h2>
      <p>
        A mid-cycle marketing email refers to an email sent during the middle stages of a customer’s buying cycle. While <em>early-cycle emails</em> focus on building awareness and attracting leads, and <em>end-cycle emails</em> are designed to push for a conversion (such as a sale or sign-up), <strong>mid-cycle emails</strong> aim to keep prospects engaged, provide valuable information, and build trust before they make a final decision.
      </p>

      <h2>Why Are Mid-Cycle Marketing Emails Important?</h2>
      <ul>
        <li><strong>Nurture Relationships</strong> After generating interest through initial touchpoints, mid-cycle emails help nurture the relationship by providing relevant content that aligns with your audience’s needs and preferences.</li>
        <li><strong>Address Objections</strong> Customers in the middle of the buying journey often have concerns or objections. Mid-cycle emails give you a chance to address those concerns head-on, reducing hesitation.</li>
        <li><strong>Build Trust and Authority</strong> Consistently providing valuable content through mid-cycle emails positions your brand as a trusted authority in your industry.</li>
        <li><strong>Boost Conversions</strong> While they’re not a direct sales push, mid-cycle emails play a crucial role in keeping leads warm, making the eventual conversion much more likely.</li>
      </ul>

      <h2>When to Send Mid-Cycle Emails?</h2>
      <p>
        Timing is everything when it comes to mid-cycle emails. Here are common scenarios when a mid-cycle email can have maximum impact:
      </p>
      <ul>
        <li><strong>After a Lead Magnet</strong> When a user downloads an ebook or signs up for a webinar, send a mid-cycle email that follows up on the lead magnet, offering additional insights or related resources.</li>
        <li><strong>Post-Free Trial or Demo</strong> If you offer free trials or product demos, a mid-cycle email can help re-engage users, provide helpful tutorials, or highlight case studies showing how others have succeeded with your product.</li>
        <li><strong>During a Long Buying Cycle</strong> In industries with long sales cycles, mid-cycle emails help maintain engagement by offering content that educates and demonstrates ongoing value.</li>
        <li><strong>Before a Big Event or Launch</strong> Mid-cycle emails can build anticipation and keep subscribers interested in what’s to come.</li>
      </ul>

      <h2>Crafting the Perfect Mid-Cycle Marketing Email</h2>

      <h3>1. Be Educational and Value-Driven</h3>
      <p>
        Mid-cycle emails should focus on providing value, whether it’s educational content, tips, or industry news. The goal here is to position your brand as a trusted resource while gently nudging the prospect toward a purchase.
      </p>
      <p><strong>Example:</strong> Send an email with a link to a blog post titled <em>"Top 5 Productivity Tips for Remote Teams"</em>.</p>

      <h3>2. Address Common Objections</h3>
      <p>
        Many leads have questions or objections that stop them from making a purchase. Your mid-cycle email is a great opportunity to address those concerns proactively.
      </p>
      <p><strong>Example:</strong> If you offer an eCommerce platform, you might send a mid-cycle email that addresses security concerns.</p>

      <h3>3. Include Case Studies or Testimonials</h3>
      <p>
        Social proof is powerful, especially during the mid-cycle stage when leads need extra reassurance. Including real-life examples of how your product or service has helped others can build trust and confidence.
      </p>
      <p><strong>Example:</strong> Share a mini case study that highlights how a specific customer achieved success using your solution.</p>

      <h3>4. Offer Exclusive Content or Resources</h3>
      <p>
        Providing exclusive resources can keep prospects engaged during the mid-cycle phase. This could be anything from advanced tutorials, industry reports, to cheat sheets.
      </p>
      <p><strong>Example:</strong> If you’re an SEO agency, send an email offering a free, in-depth SEO audit or a downloadable checklist of "SEO Tactics to Dominate Local Search."</p>

      <h3>5. Incorporate Personalized Recommendations</h3>
      <p>
        Mid-cycle emails are a great place to use customer data to offer personalized recommendations. Tailor the content to resonate with the individual recipient.
      </p>
      <p><strong>Example:</strong> "Since you attended our webinar on <em>Boosting Sales with Email Automation</em>, here’s a free guide on advanced email strategies."</p>

      <h2>Effective Mid-Cycle Marketing Email Examples</h2>
      <h3>Example 1: SaaS Trial to Paid Conversion</h3>
      <p><strong>Subject:</strong> "Getting the Most Out of Your [Product] Trial"</p>
      <p><strong>Body:</strong> A brief guide on how to unlock advanced features during the free trial. Include customer success stories from trial users who converted to paid users.</p>

      <h3>Example 2: eCommerce Post-Purchase Cross-Sell</h3>
      <p><strong>Subject:</strong> "Enhance Your Purchase with These Add-ons"</p>
      <p><strong>Body:</strong> Highlight related products based on the customer’s previous purchase.</p>

      <h3>Example 3: B2B Mid-Cycle Educational Content</h3>
      <p><strong>Subject:</strong> "5 Strategies to Improve Your Team’s Efficiency"</p>
      <p><strong>Body:</strong> Offer a link to a blog post or ebook on strategies to optimize workflows.</p>

      <h2>Tools for Crafting and Tracking Mid-Cycle Emails</h2>
      <ul>
        <li><strong>Mailchimp</strong> Great for small to mid-sized businesses with built-in automation features.</li>
        <li><strong>HubSpot</strong> An all-in-one marketing platform that provides advanced email segmentation and automation tools.</li>
        <li><strong>MarketLab</strong> For comprehensive email tracking, audience segmentation, and testing subject lines to see what resonates most with your audience.</li>
      </ul>

      <h2>Summary of Key Takeaways</h2>
      <ul>
        <li style={{display: 'block'}}><strong>Mid-Cycle Emails</strong> are crucial in nurturing leads during the middle of the buying process, helping to maintain engagement and build trust.</li>
        <li style={{display: 'block'}}>Focus on providing <strong>value-driven content</strong> that educates, addresses objections, and highlights your brand's authority.</li>
        <li style={{display: 'block'}}>Use a mix of <span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>social proof, exclusive resources, and personalized recommendations</span> to push prospects further down the sales funnel.</li>
        <li style={{display: 'block'}}>Incorporate tracking and automation tools like <strong>MarketLab</strong> to optimize your mid-cycle email campaigns and improve overall marketing performance.</li>
      </ul>

      <p>By using mid-cycle emails effectively, you’ll nurture your leads, keep them engaged, and drive them closer to making a purchase decision.</p>

        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>

        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            
            <p>
        In the lifecycle of a customer journey, the timing and content of your emails play a crucial role in nurturing leads, retaining customers, and driving conversions. One important, yet often underutilized, element of email marketing is the <strong>mid-cycle marketing email</strong>. Positioned between the initial awareness stages and the final conversion, this type of email helps maintain engagement, address doubts, and keep your brand top of mind.
      </p>

      <h2>What Is a Mid-Cycle Marketing Email?</h2>
      <p>
        A mid-cycle marketing email refers to an email sent during the middle stages of a customer’s buying cycle. While <em>early-cycle emails</em> focus on building awareness and attracting leads, and <em>end-cycle emails</em> are designed to push for a conversion (such as a sale or sign-up), <strong>mid-cycle emails</strong> aim to keep prospects engaged, provide valuable information, and build trust before they make a final decision.
      </p>

      <h2>Why Are Mid-Cycle Marketing Emails Important?</h2>
      <ul>
        <li><strong>Nurture Relationships</strong> After generating interest through initial touchpoints, mid-cycle emails help nurture the relationship by providing relevant content that aligns with your audience’s needs and preferences.</li>
        <li><strong>Address Objections</strong> Customers in the middle of the buying journey often have concerns or objections. Mid-cycle emails give you a chance to address those concerns head-on, reducing hesitation.</li>
        <li><strong>Build Trust and Authority</strong> Consistently providing valuable content through mid-cycle emails positions your brand as a trusted authority in your industry.</li>
        <li><strong>Boost Conversions</strong> While they’re not a direct sales push, mid-cycle emails play a crucial role in keeping leads warm, making the eventual conversion much more likely.</li>
      </ul>

      <h2>When to Send Mid-Cycle Emails?</h2>
      <p>
        Timing is everything when it comes to mid-cycle emails. Here are common scenarios when a mid-cycle email can have maximum impact:
      </p>
      <ul>
        <li><strong>After a Lead Magnet</strong> When a user downloads an ebook or signs up for a webinar, send a mid-cycle email that follows up on the lead magnet, offering additional insights or related resources.</li>
        <li><strong>Post-Free Trial or Demo</strong> If you offer free trials or product demos, a mid-cycle email can help re-engage users, provide helpful tutorials, or highlight case studies showing how others have succeeded with your product.</li>
        <li><strong>During a Long Buying Cycle</strong> In industries with long sales cycles, mid-cycle emails help maintain engagement by offering content that educates and demonstrates ongoing value.</li>
        <li><strong>Before a Big Event or Launch</strong> Mid-cycle emails can build anticipation and keep subscribers interested in what’s to come.</li>
      </ul>

      <h2>Crafting the Perfect Mid-Cycle Marketing Email</h2>

      <h3>1. Be Educational and Value-Driven</h3>
      <p>
        Mid-cycle emails should focus on providing value, whether it’s educational content, tips, or industry news. The goal here is to position your brand as a trusted resource while gently nudging the prospect toward a purchase.
      </p>
      <p><strong>Example:</strong> Send an email with a link to a blog post titled <em>"Top 5 Productivity Tips for Remote Teams"</em>.</p>

      <h3>2. Address Common Objections</h3>
      <p>
        Many leads have questions or objections that stop them from making a purchase. Your mid-cycle email is a great opportunity to address those concerns proactively.
      </p>
      <p><strong>Example:</strong> If you offer an eCommerce platform, you might send a mid-cycle email that addresses security concerns.</p>

      <h3>3. Include Case Studies or Testimonials</h3>
      <p>
        Social proof is powerful, especially during the mid-cycle stage when leads need extra reassurance. Including real-life examples of how your product or service has helped others can build trust and confidence.
      </p>
      <p><strong>Example:</strong> Share a mini case study that highlights how a specific customer achieved success using your solution.</p>

      <h3>4. Offer Exclusive Content or Resources</h3>
      <p>
        Providing exclusive resources can keep prospects engaged during the mid-cycle phase. This could be anything from advanced tutorials, industry reports, to cheat sheets.
      </p>
      <p><strong>Example:</strong> If you’re an SEO agency, send an email offering a free, in-depth SEO audit or a downloadable checklist of "SEO Tactics to Dominate Local Search."</p>

      <h3>5. Incorporate Personalized Recommendations</h3>
      <p>
        Mid-cycle emails are a great place to use customer data to offer personalized recommendations. Tailor the content to resonate with the individual recipient.
      </p>
      <p><strong>Example:</strong> "Since you attended our webinar on <em>Boosting Sales with Email Automation</em>, here’s a free guide on advanced email strategies."</p>

      <h2>Effective Mid-Cycle Marketing Email Examples</h2>
      <h3>Example 1: SaaS Trial to Paid Conversion</h3>
      <p><strong>Subject:</strong> "Getting the Most Out of Your [Product] Trial"</p>
      <p><strong>Body:</strong> A brief guide on how to unlock advanced features during the free trial. Include customer success stories from trial users who converted to paid users.</p>

      <h3>Example 2: eCommerce Post-Purchase Cross-Sell</h3>
      <p><strong>Subject:</strong> "Enhance Your Purchase with These Add-ons"</p>
      <p><strong>Body:</strong> Highlight related products based on the customer’s previous purchase.</p>

      <h3>Example 3: B2B Mid-Cycle Educational Content</h3>
      <p><strong>Subject:</strong> "5 Strategies to Improve Your Team’s Efficiency"</p>
      <p><strong>Body:</strong> Offer a link to a blog post or ebook on strategies to optimize workflows.</p>

      <h2>Tools for Crafting and Tracking Mid-Cycle Emails</h2>
      <ul>
        <li><strong>Mailchimp</strong> Great for small to mid-sized businesses with built-in automation features.</li>
        <li><strong>HubSpot</strong> An all-in-one marketing platform that provides advanced email segmentation and automation tools.</li>
        <li><strong>MarketLab</strong> For comprehensive email tracking, audience segmentation, and testing subject lines to see what resonates most with your audience.</li>
      </ul>

      <h2>Summary of Key Takeaways</h2>
      <ul>
        <li style={{display: 'block'}}><strong>Mid-Cycle Emails</strong> are crucial in nurturing leads during the middle of the buying process, helping to maintain engagement and build trust.</li>
        <li style={{display: 'block'}}>Focus on providing <strong>value-driven content</strong> that educates, addresses objections, and highlights your brand's authority.</li>
        <li style={{display: 'block'}}>Use a mix of <span style={{backgroundColor: 'lightyellow', fontFamily: 'Quicksand'}}>social proof, exclusive resources, and personalized recommendations</span> to push prospects further down the sales funnel.</li>
        <li style={{display: 'block'}}>Incorporate tracking and automation tools like <strong>MarketLab</strong> to optimize your mid-cycle email campaigns and improve overall marketing performance.</li>
      </ul>

      <p>By using mid-cycle emails effectively, you’ll nurture your leads, keep them engaged, and drive them closer to making a purchase decision.</p>


        </div>
    </MContainer>
    }

    </>
  )
}

export default MidCycleMarketingEmail 


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 1000px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 70px;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;

        > img {
        width: 100%;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 50px;
    }
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }

            > img {
                width: 100%;
                align-self: self-start;
                max-width: 1000px; 
                border-radius: 3px;
                object-fit: contain;
                margin-bottom: 50px;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: bold;
            margin-left: 10px;
            width: 90%;
            margin-top: 45px;
            font-size: 28px;
        }

        > h3 {
            font-weight: normal !important;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: larger;
            line-height: 1.8rem;
            width: 95%;

            > code {
                display: inline;
                width: 95%;
            }
        }
        
        > ul {
            margin-bottom: 35px;
            margin-top: 30px;
            font-weight: normal;
            font-size: larger;

            > li {
                font-weight: normal;
                margin-block: 35px;
                width: 90%;
                > strong {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`