import React, { useState } from 'react';
import { posts } from './posts';

const CategoryPage = ({ sWidth }) => {
    const category = window.location.pathname.split('/').pop().replaceAll('-', ' ');

    const filteredPosts = posts
        .filter(post => post.cat.split('&').map(cat => cat.trim().toLowerCase()).includes(category.toLowerCase()))
        .sort((a, b) => a.n - b.n);

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Category: <span style={{fontFamily: 'Quicksand', fontWeight: '300', color: 'blue', textTransform: category === 'seo'? 'uppercase': 'capitalize'}}>{category}</span></h1>
            <div style={styles.postsContainer}>
                {filteredPosts.length > 0 ? (
                    filteredPosts.reverse().map(post => (
                        <PostCard key={post.n} post={post} />
                    ))
                ) : (
                    <p style={styles.noPosts}>No posts found for this category.</p>
                )}
            </div>
        </div>
    );
};

const PostCard = ({ post }) => {
    const [isTitleHovered, setIsTitleHovered] = useState(false);
    const [isAuthorHovered, setIsAuthorHovered] = useState(false);

    return (
        <div style={styles.postCard}>
            <h2 
                style={{
                    ...styles.title,
                    color: isTitleHovered ? 'dodgerblue' : 'blue'
                }}
                onClick={() => window.location.href = `/blog/${post.title.toLowerCase().replaceAll(' ', '-')}`}
                onMouseEnter={() => setIsTitleHovered(true)}
                onMouseLeave={() => setIsTitleHovered(false)}
            >
                {post.title}
            </h2>
            <p style={styles.description}>{post.desc}</p>
            <div style={styles.meta}>
                <span 
                    style={{
                        ...styles.author,
                        color: isAuthorHovered ? 'blue' : 'inherit'
                    }}
                    onClick={() => window.location.href = `/authors/${post.author.toLowerCase().replaceAll(' ', '-')}`}
                    onMouseEnter={() => setIsAuthorHovered(true)}
                    onMouseLeave={() => setIsAuthorHovered(false)}
                >
                    {post.author}
                </span>
                <span style={styles.date}>{post.date}</span>
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        fontFamily: 'Quicksand, sans-serif',
    },
    header: {
        fontSize: '24px',
        marginBottom: '50px',
        textAlign: 'center',
        fontFamily: 'Quicksand, sans-serif',
        marginTop: '60px',
        fontWeight: '300'
    },
    postsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginBlock: '60px',
    },
    postCard: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '15px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        fontFamily: 'Quicksand, sans-serif',
    },
    title: {
        fontSize: '20px',
        margin: '0 0 10px',
        fontFamily: 'Quicksand, sans-serif',
        cursor: 'pointer',
        transition: 'color 0.3s ease-in-out',
        fontWeight: '300',
    },
    description: {
        fontSize: '16px',
        margin: '0 0 10px',
        fontFamily: 'Quicksand, sans-serif',
    },
    meta: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '14px',
        color: '#555',
        fontFamily: 'Quicksand, sans-serif',
    },
    author: {
        fontWeight: '300',
        cursor: 'pointer',
        transition: 'color 0.3s ease-in-out',
        fontFamily: 'Quicksand, sans-serif',
    },
    date: {
        fontFamily: 'Quicksand, sans-serif',
    },
    noPosts: {
        fontSize: '16px',
        textAlign: 'center',
        color: '#888',
        fontFamily: 'Quicksand, sans-serif',
    },
};

export default CategoryPage;
