import React from 'react';
import styled from 'styled-components';
import logo from '../media/11.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer({ sWidth }) {
  return (
    <Container sWidth={sWidth}>
      <div>
        <img src={logo} alt='logo' />
      </div>

      <div>
        <h4>Improwth Company</h4>
        <div><h5>CEO </h5><span>Fahad Aldossary</span></div>
        <div><h5>Sales Manager </h5><span>Charlotte Miller</span></div>
        <div><h5>CMO </h5><span>Alvaro Hernandez</span></div>
      </div>

      {sWidth > 768 &&<>
      <div>
        <h4>Links</h4>
        <p onClick={()=> window.location.href = '/products'}>Products</p>
        <p onClick={()=> window.location.href = '/about'}>Who are we</p>
        <p onClick={()=> window.location.href = '/contact'}>Get in touch with us</p>
      </div>

      <div>
        <h4>Social Media</h4>
        <LinkedInIcon  onClick={()=> window.open('https://www.linkedin.com/company/improwth/', '_blank')}/>
      </div>
      </>}


      {sWidth < 768 && <>
      <div className='ms'>
      <div>
        <h4>Links</h4>
        <p onClick={()=> window.location.href = '/products'}>Products</p>
        <p onClick={()=> window.location.href = '/about'}>Who are we</p>
        <p onClick={()=> window.location.href = '/contact'}>Get in touch with us</p>
      </div>

      <div>
        <h4>Social Media</h4>
        <LinkedInIcon  onClick={()=> window.open('https://www.linkedin.com/company/improwth/', '_blank')}/>
      </div>
      </div>
      </>}
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--root);
  padding-block: 60px;
  color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding-block: 30px;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 200px;

    @media (max-width: 768px) {
      height: auto;
    }

    > img {
      width: ${props=> props.sWidth > 768? 160: 160}px;
      object-fit: contain;
      margin-top: ${props=> props.sWidth > 768? 0: 20}px;;
    }

    > p {
      font-weight: 300;
      font-family: 'Crimson Text';
      line-height: 35px;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: dodgerblue;
      }
    }

    > h4 {
      font-size: large;
      font-weight: 300;
      font-family: 'Crimson Text';
      margin-bottom: 25px;
      color: lightgray;

      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
    }

    > .MuiSvgIcon-root {
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: dodgerblue;
      }
    }

    > div {
      display: flex;
      width: 260px;

      @media (max-width: 768px) {
        width: 100%;
        text-align: left;
      }

      > h5 {
        font-family: 'Crimson Text';
        font-weight: 300;
        width: 100px;
      }

      > span {
        margin-left: 10px;
        color: dodgerblue;
        width: 160px;
        font-family: 'Crimson Text';

        @media (max-width: 768px) {
          margin-left: 0;
          width: auto;
        }
      }
    }
  }


  > .ms {
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: column;

        > h4 {
            font-size: large;
            font-weight: 300;
            font-family: 'Crimson Text';
            margin-bottom: 15px;
            margin-top: 20px;
            color: lightgray;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-weight: 300;
            font-family: 'Crimson Text';
            line-height: 30px;
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: dodgerblue;
            }
        }
    }
  }
`;
