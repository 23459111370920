import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import img1 from '../../../media/blog/st_1.jpeg'
import img2 from '../../../media/blog/st_2.jpeg'
import img3 from '../../../media/blog/st_3.jpeg'
import img4 from '../../../media/blog/mst1.jpeg'
import img5 from '../../../media/blog/mst2.jpeg'
import img6 from '../../../media/blog/sm_1.jpeg'
import img7 from '../../../media/blog/sm2.jpeg'
import img8 from '../../../media/blog/sm1.jpeg'
import img9 from '../../../media/blog/ct1.jpeg'
import img10 from '../../../media/blog/ct3.jpeg'
import img11 from '../../../media/blog/ct2.jpeg'
import img12 from '../../../media/blog/content.jpeg'
import img13 from '../../../media/blog/kd1.jpeg'
import img14 from '../../../media/blog/kd3.jpeg'
import img15 from '../../../media/blog/kd2.jpeg'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';

function TechnicalSEOVsOnPageSEO({ sWidth, title, desc, author, date }) {
    const category = 'seo'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Uncover the crucial differences between Technical SEO and On-Page SEO. Learn how each contributes to your website’s success and why both are vital for achieving top search rankings." />
        <title>Technical SEO vs On-Page SEO: Understanding the Key Differences and Why They Matter</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'><label onClick={()=> window.location.href = `/blog/category/${category.replaceAll(' ', '-')}`} style={{textTransform: category === 'seo'? 'uppercase': 'capitalize'}}>{category}</label></div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>When diving into the world of SEO, you’ll often come across the terms "Technical SEO" and "On-Page SEO." While they both aim to enhance your website's visibility and performance, they tackle different aspects of SEO. As someone deeply involved in optimizing websites, I’ve found that understanding the distinction between these two types of SEO is crucial for a comprehensive strategy. Let’s break down what each term means, how they differ, and why both are essential for your SEO success.</p>


            <h2>What Is Technical SEO?</h2>
            <p>Technical SEO refers to the backend elements of your website that help search engines crawl and index your site effectively. It focuses on optimizing the infrastructure of your website to ensure that search engines can access and understand your content without any hiccups. Here’s what technical SEO typically involves:</p>

            <h3>Site Speed</h3>
            <p>Ensuring that your website loads quickly. Slow-loading sites can harm your rankings and user experience.</p>

            <div className='a-box'>
                <p>You can test your site’s speed for free by using <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>’s Page Speed Test. Go to the test section, select Site Speed, and enter your URL to see how fast your site loads.</p>
                <div>
                    <img src={img1} alt='site speed tool' />
                    <img src={img2} alt='site speed tool' />
                </div>
                <img src={img3} alt='site speed tool' />
            </div>

            <h3>Mobile Friendliness</h3>
            <p>Making sure your site is optimized for mobile devices, which is crucial given the increasing number of mobile searches.</p>

            <div className='a-box'>
                <p>You can check your site’s mobile friendliness for free by going to <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>, then navigating to the test section and selecting Mobile Support Test. Enter your site URL to perform the test.</p>
                <div>
                    <img src={img4} alt='mobile support tool' />
                    <img src={img5} alt='mobile support tool' />
                </div>
            </div>

            <h3>XML Sitemaps</h3>
            <p>Creating and submitting sitemaps to help search engines understand the structure of your site. <br/> <span onClick={()=> window.open('https://improwth.com/blog/how-to-find-and-optimize-sitemaps-for-enhanced-seo', '_blank')} style={{fontFamily: 'Quicksand', color: 'dodgerblue', cursor: 'pointer'}}>Read more about sitemaps</span></p>
            <div className='a-box'>
                <p>Navigate to the 'Search Engines' section in <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>, select 'Sitemaps,' and then enter the URL to view real-time data on sitemap status and visibility.</p>
                <div>
                    <img src={img6} alt='sitemaps tool' />
                    <img src={img7} alt='sitemaps tool' style={{width: '800px'}}/>
                </div>
                <img src={img8} alt='sitemaps tool' />
            </div>

            <h3>Robots.txt File</h3>
            <p>Configuring this file to manage search engine crawling permissions.</p>
            

            <h3>HTTPS</h3>
            <p>Securing your site with HTTPS to enhance security and build trust with users and search engines alike.</p>


            <h3>Site Architecture</h3>
            <p>Structuring your website in a way that is easy for both users and search engines to navigate.</p>
            


            <h2>Essential Technical SEO Elements</h2>

            <h3>Crawl Errors and Fixes</h3>
            <p>Identifying and correcting crawl errors to ensure search engines can access your pages. <br/> <span onClick={()=> window.open('https://improwth.com/blog/how-to-perform-a-search-engine-crawlability-test-for-better-seo', '_blank')} style={{fontFamily: 'Quicksand', color: 'dodgerblue', cursor: 'pointer'}}>Read more about how to perform a search engine crawlability test for better SEO</span></p>
            <div className='a-box'>
                <p>Use <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span> search engine crawlability test to find and fix issues that might be blocking search engines from accessing your site.</p>
                <div>
                    <img src={img9} alt='search engine crawlability test tool' />
                    <img src={img10} alt='search engine crawlability test tool' />
                </div>
                <img src={img11} alt='search engine crawlability test tool' />
            </div>

            <h3>Redirects and Canonical Tags</h3>
            <p>Managing redirects and using canonical tags to avoid duplicate content issues and guide search engines to the preferred version of a page.</p>
        
            <h3>Structured Data Markup</h3>
            <p>Implementing schema markup to help search engines understand your content better and enhance search result visibility.</p>

            <h3>Server Performance</h3>
            <p>Ensuring your web hosting server is reliable and performs well to avoid downtime and slow speeds.</p>

            <h3>URL Structure</h3>
            <p>Creating SEO-friendly URLs that are clean, descriptive, and easy to understand.</p>

            
            
            <h2>What Is On-Page SEO?</h2>
            <p>On-Page SEO, on the other hand, deals with the elements of your website that you can directly control on each page. It’s all about optimizing individual pages to rank higher and earn more relevant traffic from search engines. Here’s a closer look at on-page SEO components:</p>


            <h3>Title Tags</h3>
            <p>Crafting unique and compelling title tags that include your target keywords.</p>

            <h3>Meta Descriptions</h3>
            <p>Writing descriptive meta tags that entice users to click through from search results.</p>

            <h3>Header Tags</h3>
            <p>Using H1, H2, H3 tags, etc., to structure your content and include relevant keywords.</p>


            <div className='a-box'>
                <p>Use <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s content tools to view and analyze meta descriptions for your webpages.</p>
                <div style={{alignSelf: 'start'}}><img src={img12} alt='content tools' /></div>
            </div>

            <h3>Content Optimization</h3>
            <p>Creating high-quality, relevant, and keyword-rich content that answers user queries.</p>

            <h3>Internal Linking</h3>
            <p>Linking to other pages within your site to enhance navigation and distribute page authority.</p>

            <h3>Image Optimization</h3>
            <p>Using alt text and optimizing image sizes to improve load times and accessibility.</p>


            <h2>Advanced On-Page SEO Techniques</h2>

            <h3>Content Freshness</h3>
            <p>Regularly updating content to keep it relevant and engaging, which can improve rankings and user satisfaction.</p>

            <h3>User Engagement Metrics</h3>
            <p>Enhancing user experience by improving dwell time, reducing bounce rates, and encouraging interaction with your content.</p>

            <h3>Content Readability</h3>
            <p>Ensuring that your content is easy to read and understand by using clear headings, bullet points, and concise language.</p>

            <h3>Keyword Density and Placement</h3>
            <p>Strategically placing keywords throughout your content to enhance relevance without keyword stuffing.<br/> <span onClick={()=> window.open('https://improwth.com/blog/how-to-measure-keyword-density-for-your-seo', '_blank')} style={{fontFamily: 'Quicksand', color: 'dodgerblue', cursor: 'pointer'}}>Read more about keyword density</span></p>

            <div className='a-box'>
                <p>Use <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s content tools to check and analyze keyword density across your content.</p>
                <div>
                    <img src={img13} alt='keyword density tool' />
                    <img src={img14} alt='keyword density tool' />
                </div>
                <img src={img15} alt='keyword density tool' />
            </div>

            <h3>Optimizing for Featured Snippets</h3>
            <p>Structuring content to target featured snippets and improve visibility in search results.</p>

            
            
            <h2>How Technical SEO and On-Page SEO Work Together</h2>

            <p>While Technical SEO and On-Page SEO are different, they work hand in hand to boost your website's performance. Technical SEO provides the foundation for search engines to access and index your content, while On-Page SEO ensures that your content is optimized and relevant for users and search engines alike.</p>

            <p>Here’s an example of how they interact: Imagine you’ve optimized a page with excellent on-page SEO practices, but your site’s speed is sluggish due to technical issues. Even the best-optimized content won’t rank well if users have a poor experience due to slow load times. Conversely, a technically sound site without strong on-page content might still struggle to attract and engage visitors.</p>

            <h2>The Impact of Technical SEO on User Experience</h2>

            <h3>Page Load Speed</h3>
            <p>How fast your pages load affects user satisfaction and retention. A slow site can lead to higher bounce rates and lower engagement.</p>

            <h3>Mobile Usability</h3>
            <p>Ensuring your site is mobile-friendly affects how users interact with your content on different devices. A seamless mobile experience can lead to higher rankings and better user satisfaction.</p>

            <h3>Accessibility</h3>
            <p>Making your site accessible to all users, including those with disabilities, enhances usability and can positively impact your SEO.</p>

            <h2>The Role of On-Page SEO in Content Strategy</h2>
            <h3>Keyword Strategy</h3>
            <p>Developing a keyword strategy that aligns with user intent and search volume helps you create content that attracts and converts.</p>

            <h3>Content Structure</h3>
            <p>Organizing content with clear headings, subheadings, and bullet points improves readability and user experience.</p>

            <h3>Meta Data Optimization</h3>
            <p>Crafting compelling meta titles and descriptions can improve click-through rates and drive more traffic to your site.</p>

            <h2>Wrap-Up</h2>
            <p>Understanding the difference between Technical SEO and On-Page SEO is essential for crafting a well-rounded SEO strategy. Technical SEO sets the stage for effective crawling and indexing, while On-Page SEO ensures that your content meets users' needs and aligns with search intent. By addressing both areas, you're not just optimizing for search engines but enhancing the overall user experience.</p>

            <p>In my experience, balancing these two aspects has been key to driving better results and achieving SEO goals. So, whether you're just starting out or looking to refine your strategy, make sure both Technical SEO and On-Page SEO are on your radar.</p>
        </MedSection>

        <RightSection>
            <img src={fr} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = '/authors/francesca-romano'}>Francesca Romano</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>
        <div className='catDiv'><label onClick={()=> window.location.href = `/blog/category/${category.replaceAll(' ', '-')}`} style={{textTransform: category === 'seo'? 'uppercase': 'capitalize'}}>{category}</label></div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>When diving into the world of SEO, you’ll often come across the terms "Technical SEO" and "On-Page SEO." While they both aim to enhance your website's visibility and performance, they tackle different aspects of SEO. As someone deeply involved in optimizing websites, I’ve found that understanding the distinction between these two types of SEO is crucial for a comprehensive strategy. Let’s break down what each term means, how they differ, and why both are essential for your SEO success.</p>


            <h2>What Is Technical SEO?</h2>
            <p>Technical SEO refers to the backend elements of your website that help search engines crawl and index your site effectively. It focuses on optimizing the infrastructure of your website to ensure that search engines can access and understand your content without any hiccups. Here’s what technical SEO typically involves:</p>

            <h3>Site Speed</h3>
            <p>Ensuring that your website loads quickly. Slow-loading sites can harm your rankings and user experience.</p>

            <div className='a-box' style={{display: 'flex', flexDirection: 'column'}}>
                <p>You can test your site’s speed for free by using <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>’s Page Speed Test. Go to the test section, select Site Speed, and enter your URL to see how fast your site loads.</p>
                {/* <div>
                    <img src={img1} alt='site speed tool' />
                    <img src={img2} alt='site speed tool' />
                </div> */}
                <img src={img3} alt='site speed tool' width={400}/>
            </div>

            <h3>Mobile Friendliness</h3>
            <p>Making sure your site is optimized for mobile devices, which is crucial given the increasing number of mobile searches.</p>

            <div className='a-box'>
                <p>You can check your site’s mobile friendliness for free by going to <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>, then navigating to the test section and selecting Mobile Support Test. Enter your site URL to perform the test.</p>
                <img src={img4} alt='mobile support tool' />
            </div>

            <h3>XML Sitemaps</h3>
            <p>Creating and submitting sitemaps to help search engines understand the structure of your site. <br/> <span onClick={()=> window.open('https://improwth.com/blog/how-to-find-and-optimize-sitemaps-for-enhanced-seo', '_blank')} style={{fontFamily: 'Quicksand', color: 'dodgerblue', cursor: 'pointer'}}>Read more about sitemaps</span></p>
            <div className='a-box'>
                <p>Navigate to the 'Search Engines' section in <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>, select 'Sitemaps,' and then enter the URL to view real-time data on sitemap status and visibility.</p>
                
                <img src={img8} alt='sitemaps tool' width={400}/>
            </div>

            <h3>Robots.txt File</h3>
            <p>Configuring this file to manage search engine crawling permissions.</p>
            

            <h3>HTTPS</h3>
            <p>Securing your site with HTTPS to enhance security and build trust with users and search engines alike.</p>


            <h3>Site Architecture</h3>
            <p>Structuring your website in a way that is easy for both users and search engines to navigate.</p>
            


            <h2>Essential Technical SEO Elements</h2>

            <h3>Crawl Errors and Fixes</h3>
            <p>Identifying and correcting crawl errors to ensure search engines can access your pages. <br/> <span onClick={()=> window.open('https://improwth.com/blog/how-to-perform-a-search-engine-crawlability-test-for-better-seo', '_blank')} style={{fontFamily: 'Quicksand', color: 'dodgerblue', cursor: 'pointer'}}>Read more about how to perform a search engine crawlability test for better SEO</span></p>
            <div className='a-box'>
                <p>Use <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span> search engine crawlability test to find and fix issues that might be blocking search engines from accessing your site.</p>
                <img src={img11} alt='search engine crawlability test tool' width={400}/>
            </div>

            <h3>Redirects and Canonical Tags</h3>
            <p>Managing redirects and using canonical tags to avoid duplicate content issues and guide search engines to the preferred version of a page.</p>
        
            <h3>Structured Data Markup</h3>
            <p>Implementing schema markup to help search engines understand your content better and enhance search result visibility.</p>

            <h3>Server Performance</h3>
            <p>Ensuring your web hosting server is reliable and performs well to avoid downtime and slow speeds.</p>

            <h3>URL Structure</h3>
            <p>Creating SEO-friendly URLs that are clean, descriptive, and easy to understand.</p>

            
            
            <h2>What Is On-Page SEO?</h2>
            <p>On-Page SEO, on the other hand, deals with the elements of your website that you can directly control on each page. It’s all about optimizing individual pages to rank higher and earn more relevant traffic from search engines. Here’s a closer look at on-page SEO components:</p>


            <h3>Title Tags</h3>
            <p>Crafting unique and compelling title tags that include your target keywords.</p>

            <h3>Meta Descriptions</h3>
            <p>Writing descriptive meta tags that entice users to click through from search results.</p>

            <h3>Header Tags</h3>
            <p>Using H1, H2, H3 tags, etc., to structure your content and include relevant keywords.</p>


            <div className='a-box'>
                <p>Use <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s content tools to view and analyze meta descriptions for your webpages.</p>
                <div style={{alignSelf: 'start'}}><img src={img12} alt='content tools' /></div>
            </div>

            <h3>Content Optimization</h3>
            <p>Creating high-quality, relevant, and keyword-rich content that answers user queries.</p>

            <h3>Internal Linking</h3>
            <p>Linking to other pages within your site to enhance navigation and distribute page authority.</p>

            <h3>Image Optimization</h3>
            <p>Using alt text and optimizing image sizes to improve load times and accessibility.</p>


            <h2>Advanced On-Page SEO Techniques</h2>

            <h3>Content Freshness</h3>
            <p>Regularly updating content to keep it relevant and engaging, which can improve rankings and user satisfaction.</p>

            <h3>User Engagement Metrics</h3>
            <p>Enhancing user experience by improving dwell time, reducing bounce rates, and encouraging interaction with your content.</p>

            <h3>Content Readability</h3>
            <p>Ensuring that your content is easy to read and understand by using clear headings, bullet points, and concise language.</p>

            <h3>Keyword Density and Placement</h3>
            <p>Strategically placing keywords throughout your content to enhance relevance without keyword stuffing.<br/> <span onClick={()=> window.open('https://improwth.com/blog/how-to-measure-keyword-density-for-your-seo', '_blank')} style={{fontFamily: 'Quicksand', color: 'dodgerblue', cursor: 'pointer'}}>Read more about keyword density</span></p>

            <div className='a-box'>
                <p>Use <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s content tools to check and analyze keyword density across your content.</p>
                <img src={img15} alt='keyword density tool' width={400}/>
            </div>

            <h3>Optimizing for Featured Snippets</h3>
            <p>Structuring content to target featured snippets and improve visibility in search results.</p>

            
            
            <h2>How Technical SEO and On-Page SEO Work Together</h2>

            <p>While Technical SEO and On-Page SEO are different, they work hand in hand to boost your website's performance. Technical SEO provides the foundation for search engines to access and index your content, while On-Page SEO ensures that your content is optimized and relevant for users and search engines alike.</p>

            <p>Here’s an example of how they interact: Imagine you’ve optimized a page with excellent on-page SEO practices, but your site’s speed is sluggish due to technical issues. Even the best-optimized content won’t rank well if users have a poor experience due to slow load times. Conversely, a technically sound site without strong on-page content might still struggle to attract and engage visitors.</p>

            <h2>The Impact of Technical SEO on User Experience</h2>

            <h3>Page Load Speed</h3>
            <p>How fast your pages load affects user satisfaction and retention. A slow site can lead to higher bounce rates and lower engagement.</p>

            <h3>Mobile Usability</h3>
            <p>Ensuring your site is mobile-friendly affects how users interact with your content on different devices. A seamless mobile experience can lead to higher rankings and better user satisfaction.</p>

            <h3>Accessibility</h3>
            <p>Making your site accessible to all users, including those with disabilities, enhances usability and can positively impact your SEO.</p>

            <h2>The Role of On-Page SEO in Content Strategy</h2>
            <h3>Keyword Strategy</h3>
            <p>Developing a keyword strategy that aligns with user intent and search volume helps you create content that attracts and converts.</p>

            <h3>Content Structure</h3>
            <p>Organizing content with clear headings, subheadings, and bullet points improves readability and user experience.</p>

            <h3>Meta Data Optimization</h3>
            <p>Crafting compelling meta titles and descriptions can improve click-through rates and drive more traffic to your site.</p>

            <h2>Wrap-Up</h2>
            <p>Understanding the difference between Technical SEO and On-Page SEO is essential for crafting a well-rounded SEO strategy. Technical SEO sets the stage for effective crawling and indexing, while On-Page SEO ensures that your content meets users' needs and aligns with search intent. By addressing both areas, you're not just optimizing for search engines but enhancing the overall user experience.</p>

            <p>In my experience, balancing these two aspects has been key to driving better results and achieving SEO goals. So, whether you're just starting out or looking to refine your strategy, make sure both Technical SEO and On-Page SEO are on your radar.</p>
        </div>
    </MContainer>
    }

    </>
  )
}

export default TechnicalSEOVsOnPageSEO


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 100%;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }

            > img {
                width: 100%;
                align-self: self-start;
                max-width: 1000px; 
                border-radius: 3px;
                object-fit: contain;
                margin-bottom: 50px;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: bold;
            margin-left: 10px;
            width: 90%;
            margin-top: 45px;
            font-size: 28px;
        }

        > h3 {
            font-weight: normal !important;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: larger;
            line-height: 1.8rem;
            width: 95%;

            > code {
                display: inline;
                width: 95%;
            }
        }
        
        > ul {
            margin-bottom: 35px;
            margin-top: 30px;
            font-weight: normal;
            font-size: larger;

            > li {
                font-weight: normal;
                margin-block: 35px;
                width: 90%;
                > strong {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`