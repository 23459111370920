import React from 'react'
import LandingPage from '../MarketLab/LandingPage'
import styled from 'styled-components'
import pImg from '../media/ml-logo-2.png'
import { Helmet } from 'react-helmet-async'

function MarketLab({ sWidth }) {
  const Product = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "MarketLab",
    "image": pImg,
    "description": "MarketLab is an all-in-one SEO and digital marketing tool designed to help users analyze market dynamics, optimize website performance, and gain competitive insights. Features include keyword research, competitor analysis, site testing, and more.",
    "brand": {
      "@type": "Brand",
      "name": "Improwth"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "20", 
      "priceValidUntil": "2024-09-30", 
      "itemCondition": "https://schema.org/NewCondition", 
      "availability": "https://schema.org/InStock",
      "url": "https://marketlab.improwth.com",
      "seller": {
        "@type": "Organization",
        "name": "Improwth"
      },
      "billingIncrement": "P1M",
      "billingFrequency": "Monthly" 
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.6",
      "reviewCount": "235" 
    }
  }


  return (
    <>
    <Helmet>
        <meta name="description" content="Transform your SEO strategy with MarketLab’s cutting-edge tools. From detailed keyword research to competitor analysis, gain powerful insights and drive exceptional results with ease."/>
        <title>MarketLab | Advanced SEO Tools for Next-Level Insights</title>
        <link rel="canonical" href="https://improwth.com/products/market-lab" />
    </Helmet>

    <Container>
      <LandingPage sWidth={sWidth}/>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(Product) }}
      />
    </Container>
    </>
  )
}

export default MarketLab

const Container = styled.div`
  height: 100%;
  z-index: 1000;
`