import React, { useEffect } from 'react'
import styled from 'styled-components'
import image from '../../../media/francesca-romano.jpg'
import image2 from '../../../media/fr2.png'
import image3 from '../../../media/fr3.png'
import fr from '../../../media/francesca_2.png'
import { posts } from '../posts'
import BlogCard from '../../BlogCard'


const segmentArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

function FrancescaRomano({ sWidth }) {
    const sPosts = posts.filter(p=> p.author === 'Francesca Romano')

    const segmentedPosts = segmentArray(sPosts.sort((a, b) => b.n - a.n), 3);

    useEffect(() => {
        document.title = 'Improwth - Francesca Romano';
        return () => {
          document.title = 'Improwth - Francesca Romano';
        };
      }, []);

  return (
    <>
    {sWidth > 768? 
    <Container>
        <div>
            <div style={{width: '600px'}}>
                <h1>Francesca Romano</h1>
                <p className='title'>SEM Certified Expert and Blogger</p>
                <p className='desc'>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
                <p className='desc'>Francesca joined Improwth to leverage her deep industry knowledge and share her extensive expertise, contributing to the advancement of our digital marketing solutions with her unique perspective.</p>
            </div>

            <div style={{justifyContent: 'center', alignItems: 'center', width: '700px'}}>
                <img src={image} alt='Francesca Romano' />
                <div className='imgs'>
                    <img src={image2} alt='Francesca Romano' />
                    <img src={image3} alt='Francesca Romano' />
                </div>
                
            </div>
        </div>

        <Posts>
            <h2>Latest Insights from Francesca Romano</h2>
            {segmentedPosts.map(ps=> {
                
                return (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', width: '90%'}}>
                    {ps.map(post=> {
                        return (
                            <BlogCard
                                key={post.title}
                                cat = {post.cat}
                                title = {post.title} 
                                desc = {post.desc}
                                author = {post.author}
                                date = {post.date}
                            />
                        )
                    })}
                </div>)
                
            })}
        </Posts>
    </Container>
    :
    <ResponsiveContainer>
                    <div>
                        <div className="text-section">
                            <h1>Francesca Romano</h1>
                            <p className='title'>SEM Certified Expert and Blogger</p>
                            <p className='desc' style={{textAlign: 'left', marginLeft: '10px'}}>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
                            <p className='desc' style={{textAlign: 'left', marginLeft: '10px'}}>Francesca joined Improwth to leverage her deep industry knowledge and share her extensive expertise, contributing to the advancement of our digital marketing solutions with her unique perspective.</p>
                        </div>
                    </div>

                    <Posts style={{margin: '60px 5px 100px 5px'}}>
                        <h2>Latest Insights from Francesca Romano</h2>
                        {sPosts.map(post => (
                            <div style={{margin: '20px 5px'}}>
                            <h2 onClick={()=> window.location.href = `/blog/${post.title.toLowerCase().replaceAll(' ', '-')}`}  style={{fontFamily: 'Quicksand', color: 'blue'}}>{post.title}</h2>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: '13px'}}>
                                <img src={fr} alt={post.author} style={{borderRadius: '50%'}} width={37} />
                                <p style={{fontFamily: 'Quicksand', fontSize: 'small'}}>{post.author}</p>
                                <p style={{marginLeft: 'auto'}}>{post.date}</p>
                            </div>
                            </div>
                        ))}
                    </Posts>
                </ResponsiveContainer>
    }
    </>
  )
}

export default FrancescaRomano



const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 60px;
        margin-top: 60px;
        

        > div {
            display: flex;
            flex-direction: column;
            margin: 20px;

            > h1 {
                font-weight: 500;
                font-family: 'Quicksand';
                color: blue;
            }

            > .title {
                color: gray;
                font-family: 'Quicksand';
            }

            > .desc {
                font-family: 'Quicksand';
                margin-top: 50px;
            }

            > img {
                object-fit: contain;
                border-radius: 4px;
                width: 500px;
            }

            > .imgs {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 30px;
                margin-top: 20px;

                > img {
                    border-radius: 4px;
                    object-fit: contain;
                    width: 200px;
                }
            }
        }
    }
`


const Posts = styled.div`
    display: flex;
    flex-direction: column !important;
    gap: 30px;
    margin-top: 60px;
    margin-bottom: 100px;

    > h2 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-bottom: 25px;
        width: 100%;
        margin-left: 25px;
    }
`



const ResponsiveContainer = styled(Container)`
    @media (max-width: 768px) {
        > div {
            flex-direction: column;

            .text-section {
                text-align: center;
                margin: 10px;
                width: 100%;
            }

            .image-section {
                width: 100%;

                > img {
                    width: 100%;
                }

                > .imgs {
                    flex-direction: column;
                    gap: 10px;

                    > img {
                        width: 100%;
                    }
                }
            }
        }
    }
`