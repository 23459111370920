import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import { useEffect, useState } from 'react';
import Products from './components/Products';
import MarketLab from './components/MarketLab';
import styled from 'styled-components';
import TrackPageViews from './TrackPageViews';
import MLDocs from './components/MLDocs';
import Events from './components/Events';
import Blog from './components/Blog';
import KeywordResearchForGoogleAds from './components/blog/sem/KeywordResearchForGoogleAds';
import FrancescaRomano from './components/blog/authors/FrancescaRomano';
import CategoryPage from './components/blog/CategoryPage';
import HowToMeasureKeywordDensity from './components/blog/seo/HowToMeasureKeywordDensity';
import SearchEngineCrawlabilityTest from './components/blog/seo/SearchEngineCrawlabilityTest';
import BacklinkManagement from './components/blog/seo/BacklinkManagement';
import HowSocialMediaAffectsSEO from './components/blog/smm/HowSocialMediaAffectsSEO'
import WhyIsItImportantForMarketersToResearchTheCompetition from './components/blog/seo/WhyIsItImportantForMarketersToResearchTheCompetition';
import HowToFindAndOptimizeSitemapsForEnhancedSEO from './components/blog/seo/HowToFindAndOptimizeSitemapsForEnhancedSEO';
import TechnicalSEOVsOnPageSEO from './components/blog/seo/TechnicalSEOVsOnPageSEO';
import AlvaroHernandez from './components/blog/authors/AlvaroHernandez';
import WhatNextAfterCompletingKeywordResearch from './components/blog/seo/WhatNextAfterCompletingKeywordResearch';
import RedirectComponent from './components/RedirectComponent';
import NaturalBacklinksIdeas from './components/blog/seo/NaturalBacklinksIdeas';
import HowToUseCompetitionResearchTool from './components/blog/seo/HowToUseCompetitionResearchTool';
import CanonicalTags from './components/blog/seo/CanonicalTags';
import RobotsTxtAndMetaRobotsTags from './components/blog/seo/RobotsTxtAndMetaRobotsTags';
import PRInDigitalMarketing from './components/blog/digital-marketing/PRInDigitalMarketing';
import EmailMarketingInboundStrategy from './components/blog/email-marketing/EmailMarketingInboundStrategy';
import B2BEmailMarketing from './components/blog/email-marketing/B2BEmailMarketing';
import ContentMarketingForManufacturers from './components/blog/content-marketing/ContentMarketingForManufacturers';
import SEMTips from './components/blog/sem/SEMTips';
import HowToCreateWinningB2BSEOCampaign from './components/blog/seo/HowToCreateWinningB2BSEOCampaign';
import HowToCarryCompetitiveAnalysisInDigitalMarketing from './components/blog/digital-marketing/HowToCarryCompetitiveAnalysisInDigitalMarketing';
import ContentSEOVsTechnicalSEO from './components/blog/seo/ContentSEOVsTechnicalSEO';
import WhySomeBacklinksHaveNoFollow from './components/blog/digital-marketing/WhySomeBacklinksHaveNoFollow';
import LocalSEOOutsideCoreLocation from './components/blog/seo/LocalSEOOutsideCoreLocation';
import BacklinkTrackingTechniques from './components/blog/seo/BacklinkTrackingTechniques';
import MidCycleMarketingEmail from './components/blog/email-marketing/MidCycleMarketingEmail';
import TypesOfBacklinksToGet from './components/blog/seo/TypesOfBacklinksToGet';
import HowToDoB2BKeywordResearch from './components/blog/seo/HowToDoB2BKeywordResearch';


function App() {
  const [sWidth, setSWidth] = useState(window.innerWidth);
  const [cPath, setCPath] = useState('/')
  const [pathListner, setPathListener] = useState(1)

  useEffect(() => {
    const handleResize = () => {
      setSWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
      setCPath(window.location.pathname.split('/').pop())
  }, [pathListner]);


  return (
    <AppBody className={cPath !== 'market-lab' && 'grad'}>
    <Router>
      <TrackPageViews />
      {cPath !== 'market-lab' && <Header sWidth={sWidth} path_listn={()=> setPathListener(prev=> prev + 1)}/>}
        <Routes>
          <Route path='/' element={<Home sWidth={sWidth}/>} />
          <Route path='/products' element={<Products sWidth={sWidth}/>} />
          <Route path='/about' element={<About sWidth={sWidth}/>} />
          <Route path='/contact' element={<Contact sWidth={sWidth}/>} />
          <Route path='/events' element={<Events sWidth={sWidth}/>} />
          <Route path='/blog' element={<Blog sWidth={sWidth}/>} />


          <Route path='/authors/francesca-romano' element={<FrancescaRomano sWidth={sWidth}/>} />
          <Route path='/authors/alvaro-hernandez' element={<AlvaroHernandez sWidth={sWidth}/>} />


          <Route path='/blog/category/:cat' element={<CategoryPage sWidth={sWidth}/>} />



          <Route path='/blog/how-to-do-keyword-research-for-google-ads' element={<KeywordResearchForGoogleAds sWidth={sWidth}/>} />
          <Route path='/blog/how-to-measure-keyword-density-for-your-seo' element={<HowToMeasureKeywordDensity sWidth={sWidth} title='How To Measure Keyword Density For Your SEO' date='31 Jul 2024' author='Francesca Romano' desc="Measure keyword density for SEO with our guide. Learn to choose and analyze keywords to ensure optimal usage and improve your content's performance"/>} />
          <Route path='/blog/how-to-perform-a-search-engine-crawlability-test-for-better-seo' element={<SearchEngineCrawlabilityTest sWidth={sWidth} title='How to Perform a Search Engine Crawlability Test for Better SEO' author='Francesca Romano' date='31 Jul 2024' desc="Learn how a search engine crawlability test can improve your website’s SEO by ensuring it is fully indexed and optimized"/>} />
          <Route path='/blog/how-to-perform-backlink-management-for-your-website' element={<BacklinkManagement sWidth={sWidth} title='How To Perform Backlink Management For Your Website' author='Francesca Romano' date='31 Jul 2024' desc="Enhance your SEO strategy by mastering backlink management with MarketLab’s advanced backlink tools"/>} />
          <Route path='/blog/how-social-media-affects-seo' element={<HowSocialMediaAffectsSEO sWidth={sWidth} title='How Social Media Affects SEO' author='Francesca Romano' date='31 Jul 2024' desc="Explore the impact of social media on SEO and how you can leverage it to enhance your search engine rankings. Learn effective strategies for optimizing your social presence and driving better SEO results"/>} />
          <Route path='/blog/why-is-it-important-for-marketers-to-research-the-competition' element={<WhyIsItImportantForMarketersToResearchTheCompetition sWidth={sWidth} title='Why Is It Important for Marketers to Research the Competition' author='Francesca Romano' date='2 Aug 2024' desc="Learn why understanding your competitors is vital for effective marketing and how to use MarketLab to gain valuable insights"/>} />
          <Route path='/blog/how-to-find-and-optimize-sitemaps-for-enhanced-seo' element={<HowToFindAndOptimizeSitemapsForEnhancedSEO sWidth={sWidth} title='How to Find and Optimize Sitemaps for Enhanced SEO' author='Francesca Romano' date='3 Aug 2024' desc="A comprehensive guide on finding and optimizing sitemaps to improve your SEO efforts"/>} />
          <Route path='/why-sitemaps-are-important' element={<Navigate to='/blog/how-to-find-and-optimize-sitemaps-for-enhanced-seo' replace/>} />
          <Route path='/blog/technical-seo-vs-on-page-seo' element={<TechnicalSEOVsOnPageSEO sWidth={sWidth} title='Technical SEO VS On Page SEO' author='Francesca Romano' date='5 Aug 2024' desc="Discover the key differences between Technical SEO and On-Page SEO and why mastering both is essential for boosting your website’s performance"/>} />
          <Route path='/blog/what-next-after-completing-keyword-research' element={<WhatNextAfterCompletingKeywordResearch sWidth={sWidth} title='What Next After Completing Keyword Research' author='Alvaro Hernandez' date='7 Aug 2024' desc="Explore essential steps to take after completing keyword research to enhance your SEO strategy"/>} />
          <Route path='/blog/natural-backlinks-ideas' element={<NaturalBacklinksIdeas sWidth={sWidth} title='Natural Backlinks Ideas' author='Alvaro Hernandez' date='12 Aug 2024' desc="Uncover creative natural backlinks ideas and strategies to elevate your SEO with engaging content and innovative techniques"/>} />
          <Route path='/blog/how-to-perform-competition-research' element={<HowToUseCompetitionResearchTool sWidth={sWidth} title='How to Perform Competition Research' author='Francesca Romano' date='18 Aug 2024' desc="Discover how to leverage MarketLab’s tools to conduct effective competition research with detailed visual insights"/>} />
          <Route path='/blog/alternate-pages-and-proper-canonical-tag-use-for-seo' element={<CanonicalTags sWidth={sWidth} title='Alternate Pages and Proper Canonical Tag Use for SEO' author='Francesca Romano' date='19 Aug 2024' desc="Learn how to manage alternate pages and use canonical tags correctly to enhance your SEO performance"/>} />
          <Route path='/blog/robots-txt-and-meta-robots-tags' element={<RobotsTxtAndMetaRobotsTags sWidth={sWidth} title='Robots txt and Meta Robots Tags' author='Francesca Romano' date='19 Aug 2024' desc="Discover how to use robots.txt and meta robots tags to control search engine crawling and indexing, optimizing your SEO strategy with our detailed guide"/>} />
          <Route path='/blog/pr-in-digital-marketing' element={<PRInDigitalMarketing sWidth={sWidth} title='PR In Digital Marketing' author='Francesca Romano' date='23 Aug 2024' desc="Learn how to leverage public relations (PR) in digital marketing to enhance brand visibility, trust, and engagement"/>} />
          <Route path='/blog/how-can-email-marketing-fuel-your-overall-inbound-strategy' element={<EmailMarketingInboundStrategy sWidth={sWidth} title='How Can Email Marketing Fuel Your Overall Inbound Strategy' author='Francesca Romano' date='23 Aug 2024' desc="Learn how email marketing can enhance your inbound strategy by nurturing leads and driving conversions"/>} />
          <Route path='/blog/how-to-leverage-email-marketing-for-b2b-lead-generation' element={<B2BEmailMarketing sWidth={sWidth} title='How to Leverage Email Marketing for B2B Lead Generation' author='Francesca Romano' date='27 Aug 2024' desc="Discover proven strategies to use email marketing for generating high-quality B2B leads and driving business growth"/>} />
          <Route path='/blog/content-marketing-for-manufacturers' element={<ContentMarketingForManufacturers sWidth={sWidth} title='Content Marketing for Manufacturers' author='Francesca Romano' date='28 Aug 2024' desc="Explore how content marketing can help manufacturers build authority, educate clients, and drive growth with actionable strategies"/>} />
          <Route path='/blog/search-engine-marketing-tips-and-best-practices' element={<SEMTips sWidth={sWidth} title='Search Engine Marketing Tips and Best Practices' author='Francesca Romano' date='29 Aug 2024' desc="Discover top search engine marketing tips and best practices to improve your SEM campaigns and drive better results"/>} />
          <Route path='/blog/how-to-create-a-winning-b2b-seo-campaign' element={<HowToCreateWinningB2BSEOCampaign sWidth={sWidth} title='How to Create a Winning B2B SEO Campaign' author='Francesca Romano' date='1 Sep 2024' desc="Master the art of B2B SEO with our comprehensive guide on creating a winning campaign, powered by MarketLab’s tools"/>} />
          <Route path='/blog/how-to-carry-competitive-analysis-in-digital-marketing' element={<HowToCarryCompetitiveAnalysisInDigitalMarketing sWidth={sWidth} title='How To Carry Competitive Analysis In Digital Marketing' author='Francesca Romano' date='3 Sep 2024' desc="Master competitive analysis in digital marketing and uncover strategies to outshine your competitors"/>} />
          <Route path='/blog/content-seo-vs-technical-seo' element={<ContentSEOVsTechnicalSEO sWidth={sWidth} title='Content SEO vs Technical SEO' author='Francesca Romano' date='5 Sep 2024' desc="Learn the key differences between content SEO and technical SEO and how to balance both for optimal search engine rankings"/>} />
          <Route path='/blog/why-some-backlinks-have-no-follow' element={<WhySomeBacklinksHaveNoFollow sWidth={sWidth} title='Why Some Backlinks Have No Follow' author='Francesca Romano' date='9 Sep 2024' desc='Understand the significance of "no follow" backlinks in digital marketing and how they impact your overall strategy'/>} />
          <Route path='/blog/building-local-seo-outside-your-core-location' element={<LocalSEOOutsideCoreLocation sWidth={sWidth} title='Building Local SEO Outside Your Core Location' author='Francesca Romano' date='11 Sep 2024' desc='Learn effective strategies to expand your local SEO beyond your core location and attract customers from nearby areas'/>} />
          <Route path='/blog/backlink-tracking-techniques' element={<BacklinkTrackingTechniques sWidth={sWidth} title='Backlink Tracking Techniques' author='Francesca Romano' date='15 Sep 2024' desc='Explore essential backlink tracking techniques to optimize your link profile and boost your SEO performance'/>} />
          <Route path='/blog/what-is-a-mid-cycle-marketing-email' element={<MidCycleMarketingEmail sWidth={sWidth} title='What Is a Mid-Cycle Marketing Email' author='Francesca Romano' date='21 Sep 2024' desc='Discover how mid-cycle marketing emails can transform your lead nurturing process and skyrocket your conversions with targeted, value-packed strategies'/>} />
          <Route path='/blog/types-of-backlinks-to-get' element={<TypesOfBacklinksToGet sWidth={sWidth} title='Types of Backlinks to Get' author='Francesca Romano' date='3 Oct 2024' desc='Discover the top 10 types of backlinks you should get for better SEO results, with actionable strategies and real-world examples'/>} />
          <Route path='/blog/how-to-do-b2b-keyword-research' element={<HowToDoB2BKeywordResearch sWidth={sWidth} title='How to Do B2B Keyword Research' author='Francesca Romano' date='17 Oct 2024' desc='Unlock the secrets of B2B keyword research to connect with decision-makers and drive your business forward'/>} />



          {/* <Route path='/affordable-seo-tools' element={<AffordableSEOTools sWidth={sWidth}/>} /> */}


          <Route path='products/market-lab/docs' element={<MLDocs sWidth={sWidth}/>} />
          <Route path='/market-lab/docs' element={<MLDocs sWidth={sWidth}/>} />

          <Route path='products/market-lab' element={<RedirectComponent url='https://marketlab.improwth.com' />} />
          <Route path='/market-lab' element={<RedirectComponent url='https://marketlab.improwth.com' />} />
        </Routes>
      {cPath !== 'market-lab' && <Footer sWidth={sWidth}/>}
    </Router>
    </AppBody>
  );
}

export default App;


const AppBody = styled.div`
  height: 100%;
  width: 100%;
`