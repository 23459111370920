import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import img1 from '../../../media/blog/comp_1.jpeg'


function HowToCarryCompetitiveAnalysisInDigitalMarketing({ sWidth, title, desc, author, date }) {
    const category = 'digital marketing'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Unlock the secrets of competitive analysis in digital marketing. Learn how to evaluate your competitors' strategies and enhance your own marketing efforts to gain a competitive edge." />
        <title>How to Carry Out a Competitive Analysis in Digital Marketing: Step-by-Step Guide</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>
        In the fast-paced world of digital marketing, staying ahead of your competitors is essential. Competitive analysis is a critical step that allows you to understand your competition's strategies, identify gaps in your own approach, and discover new opportunities for growth. This guide will walk you through the steps to effectively carry out competitive analysis in digital marketing.
      </p>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step One: </span>Identify Your Key Competitors</h2>
      <p>
        The first step in any competitive analysis is to identify who your key competitors are. These are the businesses that compete with you for the same audience, offering similar products or services. You can start by listing direct competitors and then expand to indirect competitors who may not offer the exact same product but still target your audience.
      </p>
      <div className='a-box'>
      <p>
        <strong>Tip:</strong> Use tools like <a href="https://improwth.com/market-lab" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>MarketLab</a> to find competitors in your niche. With its competitor analysis tool, you can easily discover who your top competitors are based on your target keywords and market segment.
      </p>
      <img src={img1} alt='competition research tool' />
      </div>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Two: </span> Analyze Their Digital Marketing Strategies</h2>
      <p>
        Once you have identified your competitors, the next step is to analyze their digital marketing strategies. This includes their SEO efforts, content marketing, social media presence, and paid advertising. Here’s a breakdown of what to look for:
      </p>
      <ul>
        <li><strong>SEO:</strong> Evaluate their on-page SEO elements, including keywords, meta descriptions, and URL structure. Use tools like MarketLab to analyze their backlink profiles and discover which keywords they are ranking for.</li>
        <li><strong>Content Marketing:</strong> Examine the types of content they produce. Are they focusing on blogs, videos, or infographics? How often do they post, and what topics do they cover?</li>
        <li><strong>Social Media:</strong> Look at their social media platforms. Which ones are they most active on, and what type of content do they share? Pay attention to engagement rates and follower growth.</li>
        <li><strong>PPC:</strong> Analyze their paid advertising efforts. What ad platforms are they using? What kind of ad copy and creatives are they running? Tools like SEMrush can provide insights into your competitors' PPC campaigns.</li>
      </ul>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Three: </span> Benchmark Their Performance</h2>
      <p>
        Benchmarking your competitors' performance is crucial to understanding where you stand in comparison. Look at key performance indicators (KPIs) such as website traffic, search engine rankings, social media engagement, and conversion rates. This data will help you gauge how well your competitors are performing and identify areas where you can improve.
      </p>
      <div className='a-box'>
      <p>
        <strong>Tip:</strong> Use <a href="https://improwth.com/market-lab" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>MarketLab’s</a> reporting features to generate performance benchmarks across various digital channels. This will give you a clear picture of where you excel and where you need to catch up.
      </p></div>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Four: </span> Identify Strengths, Weaknesses, Opportunities, and Threats (SWOT Analysis)</h2>
      <p>
        A SWOT analysis helps you synthesize all the data you've gathered into actionable insights. Identify your competitors' strengths (what they do well), weaknesses (where they fall short), opportunities (gaps in the market that you can exploit), and threats (areas where they may pose a significant challenge).
      </p>
      <p>
        <strong>Example:</strong> If you find that a competitor is weak in content marketing but strong in PPC, you could focus on building a robust content strategy to capitalize on that gap while being mindful of their paid advertising dominance.
      </p>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Five: </span> Adapt and Refine Your Strategy</h2>
      <p>
        Finally, use the insights gained from your competitive analysis to refine your digital marketing strategy. Whether it's tweaking your SEO approach, ramping up content production, or adjusting your social media tactics, the goal is to stay ahead of the competition by constantly evolving your strategy based on the data you've collected.
      </p>
      <p>
        Remember, competitive analysis isn't a one-time task. The digital landscape is always changing, and staying competitive means regularly monitoring your rivals and adjusting your tactics as needed.
      </p>

      <h2>Closing Thoughts</h2>
      <p>
        Competitive analysis in digital marketing is an ongoing process that requires diligence and strategic thinking. By understanding your competitors’ strengths and weaknesses and leveraging tools like MarketLab, you can fine-tune your marketing efforts and achieve better results. Start your analysis today and take your digital marketing strategy to the next level.
      </p>

        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>

        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>
        In the fast-paced world of digital marketing, staying ahead of your competitors is essential. Competitive analysis is a critical step that allows you to understand your competition's strategies, identify gaps in your own approach, and discover new opportunities for growth. This guide will walk you through the steps to effectively carry out competitive analysis in digital marketing.
      </p>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step One: </span>Identify Your Key Competitors</h2>
      <p>
        The first step in any competitive analysis is to identify who your key competitors are. These are the businesses that compete with you for the same audience, offering similar products or services. You can start by listing direct competitors and then expand to indirect competitors who may not offer the exact same product but still target your audience.
      </p>
      <div className='a-box'>
      <p>
        <strong>Tip:</strong> Use tools like <a href="https://improwth.com/market-lab" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>MarketLab</a> to find competitors in your niche. With its competitor analysis tool, you can easily discover who your top competitors are based on your target keywords and market segment.
      </p>
      <img src={img1} alt='competition research tool' />
      </div>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Two: </span> Analyze Their Digital Marketing Strategies</h2>
      <p>
        Once you have identified your competitors, the next step is to analyze their digital marketing strategies. This includes their SEO efforts, content marketing, social media presence, and paid advertising. Here’s a breakdown of what to look for:
      </p>
      <ul>
        <li><strong>SEO:</strong> Evaluate their on-page SEO elements, including keywords, meta descriptions, and URL structure. Use tools like MarketLab to analyze their backlink profiles and discover which keywords they are ranking for.</li>
        <li><strong>Content Marketing:</strong> Examine the types of content they produce. Are they focusing on blogs, videos, or infographics? How often do they post, and what topics do they cover?</li>
        <li><strong>Social Media:</strong> Look at their social media platforms. Which ones are they most active on, and what type of content do they share? Pay attention to engagement rates and follower growth.</li>
        <li><strong>PPC:</strong> Analyze their paid advertising efforts. What ad platforms are they using? What kind of ad copy and creatives are they running? Tools like SEMrush can provide insights into your competitors' PPC campaigns.</li>
      </ul>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Three: </span> Benchmark Their Performance</h2>
      <p>
        Benchmarking your competitors' performance is crucial to understanding where you stand in comparison. Look at key performance indicators (KPIs) such as website traffic, search engine rankings, social media engagement, and conversion rates. This data will help you gauge how well your competitors are performing and identify areas where you can improve.
      </p>
      <div className='a-box'>
      <p>
        <strong>Tip:</strong> Use <a href="https://improwth.com/market-lab" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>MarketLab’s</a> reporting features to generate performance benchmarks across various digital channels. This will give you a clear picture of where you excel and where you need to catch up.
      </p></div>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Four: </span> Identify Strengths, Weaknesses, Opportunities, and Threats (SWOT Analysis)</h2>
      <p>
        A SWOT analysis helps you synthesize all the data you've gathered into actionable insights. Identify your competitors' strengths (what they do well), weaknesses (where they fall short), opportunities (gaps in the market that you can exploit), and threats (areas where they may pose a significant challenge).
      </p>
      <p>
        <strong>Example:</strong> If you find that a competitor is weak in content marketing but strong in PPC, you could focus on building a robust content strategy to capitalize on that gap while being mindful of their paid advertising dominance.
      </p>

      <h2><span style={{fontFamily: 'Quicksand', color: 'dodgerblue', fontWeight: 'bold'}}>Step Five: </span> Adapt and Refine Your Strategy</h2>
      <p>
        Finally, use the insights gained from your competitive analysis to refine your digital marketing strategy. Whether it's tweaking your SEO approach, ramping up content production, or adjusting your social media tactics, the goal is to stay ahead of the competition by constantly evolving your strategy based on the data you've collected.
      </p>
      <p>
        Remember, competitive analysis isn't a one-time task. The digital landscape is always changing, and staying competitive means regularly monitoring your rivals and adjusting your tactics as needed.
      </p>

      <h2>Closing Thoughts</h2>
      <p>
        Competitive analysis in digital marketing is an ongoing process that requires diligence and strategic thinking. By understanding your competitors’ strengths and weaknesses and leveraging tools like MarketLab, you can fine-tune your marketing efforts and achieve better results. Start your analysis today and take your digital marketing strategy to the next level.
      </p>

        </div>
    </MContainer>
    }

    </>
  )
}

export default HowToCarryCompetitiveAnalysisInDigitalMarketing 


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 1000px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 70px;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;

        > img {
        width: 100%;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 50px;
    }
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }

            > img {
                width: 100%;
                align-self: self-start;
                max-width: 1000px; 
                border-radius: 3px;
                object-fit: contain;
                margin-bottom: 50px;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: bold;
            margin-left: 10px;
            width: 90%;
            margin-top: 45px;
            font-size: 28px;
        }

        > h3 {
            font-weight: normal !important;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: larger;
            line-height: 1.8rem;
            width: 95%;

            > code {
                display: inline;
                width: 95%;
            }
        }
        
        > ul {
            margin-bottom: 35px;
            margin-top: 30px;
            font-weight: normal;
            font-size: larger;

            > li {
                font-weight: normal;
                margin-block: 35px;
                width: 90%;
                > strong {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`