import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import Groups3Icon from '@mui/icons-material/Groups3';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import SearchIcon from '@mui/icons-material/Search';
import ScienceIcon from '@mui/icons-material/Science';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SearchIcon />,
    title: 'Advanced Keyword Research',
    description:
      'Discover high-performing keywords tailored to your niche, analyze search volumes, competition levels, CPC, and keyword intent to optimize your content and boost your search engine rankings',
  },
  {
    icon: <Groups3Icon />,
    title: 'In-Depth Competition Analysis',
    description:
      "Gain valuable insights into your competitors' strategies, including traffic sources, top-performing keywords, and backlink profiles, to identify market gaps and stay ahead of the competition",
  },
  {
    icon: <InsertLinkIcon />,
    title: 'Comprehensive Backlink Analysis',
    description:
      "Examine your backlink profile, identify high-quality link-building opportunities, and track your progress over time to enhance your site's authority and improve search engine rankings",
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Great user experience',
    description:
      'Integrate our product into your routine with an intuitive and easy-to-use interface.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Frequent Feature Updates',
    description:
      "Stay ahead with Market Lab’s commitment to innovation. Benefit from frequent feature updates that introduce new tools and enhancements, ensuring you always have the latest capabilities to improve your SEO and digital marketing strategies",
  },
  {
    icon: <ScienceIcon />,
    title: 'Robust Website Testing Tools',
    description:
      "Analyze and improve your website's performance, usability, and speed with advanced testing tools. Conduct A/B tests, monitor page load times, and fix issues to ensure an exceptional user experience",
  }
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Discover why Market Lab stands out: with accurate data, a diverse range of tools, user-friendly design, and innovation. Experience the benefits of real-time data and precision in every detail
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
