import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from 'react-helmet-async';


function Contact({ sWidth }) {


      useEffect(() => {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Improwth",
            "url": "https://improwth.com",
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "contactType": "Customer Service",
                    "email": "reach@improwth.com",
                    "telephone": "+1-803-692-7246",
                    "areaServed": "US",
                    "availableLanguage": "English"
                },
                {
                    "@type": "ContactPoint",
                    "contactType": "WhatsApp Support",
                    "telephone": "+1-540-614-0717",
                    "areaServed": "US",
                    "availableLanguage": "English"
                }
            ]
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);
    

  return (
    <>
    <Helmet>
        <meta name="description" content="Have questions or need support? Reach out to the Improwth team through our contact page. We’re here to assist you with inquiries, feedback, and partnership opportunities."/>
        <title>Contact Us | Get in Touch with Improwth</title>
        <link rel="canonical" href="https://improwth.com/contact" />
    </Helmet>

    <Container sWidth={sWidth}>
        <div>
            <Card type='email' sWidth={sWidth}>
                <h3><EmailIcon /> Email:</h3>
                <p>reach@improwth.com</p>
            </Card>
            <Card type='phone' sWidth={sWidth}>
                <h3><PhoneIcon /> Call:</h3>
                <p>+1 803 692 7246</p>
            </Card>
            <Card type='whatsapp' sWidth={sWidth}>
                <h3><WhatsAppIcon /> WhatsApp</h3>
                <p>+1 540 614 0717</p>
            </Card>
        </div>
    </Container>
    </>
  )
}

export default Contact


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 90vh;


    > div {
        display: flex;
        flex-direction: ${props=> props.sWidth > 768? 'row': 'column'};
        align-items: center;
        justify-content: center;
        gap: 20px;
        height: 85%;
    }
`

const FadeEffect = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: fit-content;
    border: 1px solid lightgray;
    border-radius: 3px;
    box-shadow: 1px 1px 20px lightgray;
    gap: 25px;
    padding: 10px 0 15px 15px;

    opacity: 0;
    animation: ${FadeEffect} 0.4s ease-in-out forwards;

    background: linear-gradient(45deg, transparent 30%, ${props=> props.type === 'email'? 'lightpink': props.type === 'phone'? 'lightgray': 'rgb(46, 222, 152)'} 70%);

    > h3 {
        font-weight: 300;
        font-family: 'Crimson Text';
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        font-size: larger;

        > .MuiSvgIcon-root {
            font-size: larger;
        }
    }

    > p {
        font-family: 'Crimson Text';
    }
`